


:root{
  --backgroudcolor:#FFFCED;
  --buttonsignin:#622E27;
  --get:#C64709;
}

.header {
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  width: auto;
  top: 0;
  z-index: 3;
  transition: transform 0.5s;
  transform: translateY(0);
}

.header.hidden {
  transform: translateY(-100%);
}

.header-content {
  padding: 10px;
  font-weight: bold;
}

.list-items {
  text-decoration: none;
  color: #5A5858;
  text-align: center;
  font-family: Poppins;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 171.5%; 
  letter-spacing: 0.257px;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 10vh;
}
.nav-logo{
  width: 199.148px;
  height: 62.072px;
}
.profile-image-nav{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.avator a {
  color: white;
}
.avator {
  background: #622E27;
  display: flex;
  height: 6vh;
  border-radius: 6px;
  padding: 15px;
  align-items: center;
  justify-content: center;
}
.signin-btn-nav {

  padding: 1px 20px;
  background-color: var(--buttonsignin);
  white-space: nowrap;
  border: none;
  font-weight: 500;
color: #FFF;
text-align: center;
font-family: Song Myung;
font-size: 1.4rem;
font-style: normal;
font-weight: 400;
line-height: 171.5%; 
letter-spacing: 0.257px;

}
.plans-dropdown{
  visibility: hidden;
  opacity: 0;
  position: absolute;
  display: block;
   margin: 16px -16px;
  padding: 0;
  background: #fce181;
  border-radius: 4px;
  transition: opacity 0.2s, visibility 0.2s;
 
}
.plans-dropdown li{
  margin: -2px 0 0 -2px;
  width: calc(100% - 20px);
  line-height: 1.7;
  list-style: none;
 
}
.plans-dropdown li a{
  color: #5A5858;
  text-decoration: none;
  font-family: Poppins, "Helvetica Neue", Helvetica, Arial;
  font-size: 18px;
  font-weight: 400;
}
.nav-item:hover .plans-dropdown{
  visibility: visible;
  opacity: 1;
  width: 225px;
  height: 125px;
  padding-inline: 12px;
  padding-block: 8px;
  text-align: left;

}
.nav-menu {
  display: flex;
  list-style: none;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 5vh;
  padding-right: 5vh;
  margin-right: 1rem;
  text-align: center;
  align-items: center;
  width: auto;
  background: linear-gradient(90deg, #FDE27F 0%, #F4E19A 32.64%, rgba(241, 235, 207, 0.91) 60.76%);
  gap: 5vh;
}
a {
  text-decoration: none;

}
.navbar-scrolled {
  background-color: var(--backgroundcolor); 
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
  cursor: pointer;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background:var(--pri, #C64709);
}

.nav-item .active {
  color:var(--pri, #C64709);
}

.nav-icon {
  display: none;
}




@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 79%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .navbar {
    height: 10vh;
    display: flex;
    justify-content: center;
    background-color: var(--backgroudcolor);
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    width: auto;
    top: 0;
    z-index: 3;
}


.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10vh;
  margin-top: 0;
  background-color: #fffced;
}
  .nav-menu.active {
    background: var(--backgroudcolor);
    left: 0px;
    width: 100%;
    height: auto;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }


  .list-items {
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: var(--buttonsignin);
  }
  
}

.header {
  height: 80px;
  padding: 20px;
  transition: background-color 0.3s; 
}

.header-blue {
  background-color: #FFFCED; 
}