@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
.Responsive-container{
    width: 100%;
    height: auto;
    background: var(--TIR, #FFFCED);
}
.Explore-title {

    height: auto;
    display: flex;
    flex-direction: column;
     padding: 2rem 4rem;
     gap:2rem;
   
}
.Explore-title h1{
color: var(--PRI, #C64709);
font-size: 34.262px;
font-style: normal;
font-weight: 500;
letter-spacing: 0.514px;
margin: 0;
}
.Explore-title p{
    color: #000;
    font-family: Poppins;
    font-size: 21.131px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.257px;
    line-height: 195%;
    margin: 0;
}
.Explore-crash{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  
}
.crashcpurse-btn{
    display: flex;
    align-items: center;
    padding-inline-end: 8rem;
    justify-content: flex-end;

}
.crash{
display: inline-flex;
padding: 8.855px 15.848px 8.12px 15.422px;
justify-content: center;
align-items: center;
gap: 9.621px;
color: aliceblue;
border: none;
background: var(--button, linear-gradient(273deg, #ED5412 0.59%, #C53718 100.05%, #C64709 100.06%));
}
.crashcpurse-btn h1{
color: #ffffff;
text-align: center;
font-family: Poppins;
font-size: 17.135px;
font-style: normal;
font-weight: 500;
letter-spacing: 0.257px;
}
a {
    text-decoration: none;
}
.Explore-crashcontainer{
    display: flex;
    padding-inline: 2rem;
    padding-block: 3rem;
    height: auto;
    gap: 2rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.box{
    border-radius: 7.525px;
    background: rgba(255, 253, 253, 0.73);
    box-shadow: 0px 2.50844px 20.557px 0px rgba(0, 0, 0, 0.10);
    padding: 1rem;
    width: 350px;
}
.box:hover{
  scale: 1.1;
  transition: all 1s ease-in-out;
  
}
.box1{
    padding: 1rem;
}
.box1-img h2{
    margin: 0;
    color: #000;
font-family: 'Poppins';
font-size: clamp(1.2rem ,2vw ,1.3rem);
font-style: normal;
font-weight: 600;
letter-spacing: 0.257px;
}
.box1-img img{
    width: 100%;
    height: 25vh;
    border-radius: 5.525px ;
    object-fit: cover;
}
.box1-img {
    display: flex;
    height: 35vh;
    
    justify-content: space-around;
    flex-direction: column;
    align-items: flex-start;
}
Link.box1-btm {
    height: 20vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
}
.box1-keywords {
    display: flex;
    align-items: center;
    width: 100%;
    gap:4px;
    margin-top: 0.5em;
    justify-content: flex-start;
}
.timing{
    position: absolute;
    margin-top: 1rem;
    margin-left: 0.5rem;
    width: auto;
    border-radius: 0.2rem;
    padding: 0.2rem;
    background: var(--card, linear-gradient(137deg, #C24609 28.16%, #FFB0A0 79.51%));
    
}
.timing p{
    margin: 0;
    color: #000;
font-family: 'Poppins';
font-size: clamp(0.5rem,1vw,1.2rem);
font-style: normal;
font-weight: 500;
letter-spacing: 0.257px;
}
.box1-keywords p{
margin: 0;
color: #000;
font-family: 'Poppins';
font-size: clamp(0.5rem,1vw,1.2rem);
font-style: normal;
font-weight: 400;
letter-spacing: 0.257px;
}
.box1-price span{
    
    color: #000;
    font-family: 'Poppins';
    font-size: clamp(0.8rem,2vw,1rem);
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.257px;
    }
.box1-price {
    display: flex;
    align-items: center;
    margin-top: 0.5em;
    justify-content: flex-start;
}
.box1-price p{
    margin: 0;
 color: #000;
font-family: 'Poppins';
font-size: clamp(1rem,2vw,1.2rem);
font-style: normal;
font-weight: 600;
letter-spacing: 0.257px;
}
.box1-btn {
    width: 100%;
    display: flex;
    margin-top: 0.5em;
    padding-block: 0.2rem;
    border-radius: 0.3rem;
    background: var(--card, linear-gradient(463deg, #C24609 54.16%, #FFB0A0 104.51%));
    align-items: center;
    justify-content: center;
}
.box1-btn p{
margin: 0;
color: #ffffff;
font-family: 'Poppins';
font-size: clamp(1rem ,2vw ,1.115rem);
font-style: normal;
font-weight: 400;
letter-spacing: 0.257px;
}
.box1-img h3{
    position: absolute;
}

@media screen and (min-width:1920px) {
     .Explore-title p {
        font-size: 48px;
        font-family: 'Poppins';
        font-weight: 400;
    }
    .box1-img h2{
        font-size: 32px;
        font-weight: 500;
    }
    .box1-keywords p{
        font-size: 24px;
        font-weight: 300;
    }
    .box1-price p{
        font-size: 40px;
        font-weight: 500;
    }
    .box1-price strong{
        font-size: 56px;
    }
    .box1-price span{
        font-size: 24px;
        font-weight: 400;
    }
    .box1-btn{
        padding-block: 0.5rem;
    }
    .box1-btn p{
        font-size: 32px;
    }
}
@media screen and (min-width:1200px) and (max-width:1919px) {

    .box1-img h2{
        font-size: 18px;
        font-weight: 500;
    }
    .box1-keywords p{
        font-size: 16px;
        font-weight: 400;
    }
    .box1-price p{
        font-size: 21px;
        font-weight: 400;
    }
    .box1-price strong{
        font-size: 32px;
    }
    .box1-price span{
        font-size: 21px;
        font-weight: 400;
    }
    .box1-btn{
        padding-block: 0.2rem;
    }
    .box1-btn p{
        font-size: 21px;
    }

}
@media screen and (min-width:992px) and (max-width:1199px) {
 
    .box1-img h2{
        font-size: 18px;
        font-weight: 400;
       
    }
    .Explore-crashcontainer{
        justify-content: space-between;
    }
    .box1-keywords p{
        font-size: 13px;
    }
    .box1-price p{
        font-size: 21px;
        
    }
    .box1-price strong{
        font-size: 27px;
    }
    .box1-price span{
        font-size: 19px;
        
    }
    .box {
        width: 265px;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
 
    .box1-img h2 {
        font-size: 24px;
    }
    .box1-keywords p {
        font-size: 16px;
        font-weight: 500;
    }
    .box1-btm{
        justify-content: space-around;
        height: 15vh;
    }
    .box1-img{
        height: auto;
        gap: 16px;
    }
    .box1-price p{
        font-size: 21px;
        
    }
    .box1-price strong{
        font-size: 27px;
    }
    .box1-price span{
        font-size: 19px;
        
    }
    .box1-btn{
        padding-block: 0.5rem;
    }
    .Explore-crashcontainer{
        padding-inline: 0;
    }
    .box {
        width: 325px;
    }
}
@media screen and (max-width: 540px) {
    .box1-keywords p{
        font-size: 1rem
    }
    .box1-btm{
        height: 15vh;
       justify-content: space-around;
    }
    .Explore-title{
        padding: 2rem 2rem;
    }
    .crashcpurse-btn{
        padding-inline-end: 2rem;
    }
}
