

@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Playfair+Display&display=swap');



.main{
    height: 55vh;
    width: 100%;
    position: relative;
}

.center{
    height: 80%;
    width: 68%;
    display: flex;
    position: absolute;
    left: 7%;
    top: 50%;
    transform: translate(0%,-50%);
}

.imgContainer{
    overflow: hidden;
    position: absolute;
    /* background-color: #F1EBCF; */
    width: 0%;
    -webkit-box-shadow: 27px 34px 45px -7px #000000; 
    box-shadow: 27px 34px 45px -7px #000000;
    height: 73vh;
}

.tra-image{
    height: 46vw;
    width: 43vw;
    opacity: 1;
}

.heading{
    overflow: hidden;
    height: 5rem;
    width: 49vw;
    z-index: 2;
    position: absolute;
    top: -16%;
    left: -50px;
}

.heading h1{
    position: absolute;
    font-size: 5.6vw;
    color: white;
    top: 100px;
    font-family: 'Cinzel', serif;
    font-weight: 400;
}

.lines{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    position: absolute;
    left: -200px;
}

.line{
    height: 120px;
    width: 2px;
}

.line.active {
    background-color: #000; 
}


.textContainer{
    position: absolute;
   
    width: 38vw;
    height: 25vw;
 
    overflow: hidden;
    padding: 10px;
    top: 40%;
    left: -140px;
    z-index: 3;
}



.text{
    position: absolute;
    top: -200%;
    font-size: 1.2rem;
    color: white;
    font-family: 'Playfair Display', serif;
    left: 6rem;
}

.imgContainer::before{
    position: absolute;
    content:" ";
  
    top: 0;
}

.icons{
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-size: 20px;
    gap: 20px;
    right: 6%;
    bottom: 20%;
}

.Hamburger{
    position: absolute;
    right: 6%;
    top: 10%;
    width: 30px;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 4px;
    height: 20px;
    z-index: 4;
}

.hamburger-line{
    width: 80%;
    height: 2px;
    background: #000;
}

.hamburger-line:nth-child(3){
    width: 60%;
}

.Tre-container{
    background: #622E27;
    display: flex;
    width: 100%;
    height: calc(106vh - 80px);
    flex-direction: column;
}

.treatise-container {
    display: flex;
    padding-inline: 2rem;
    justify-content: space-evenly;
}
.TREATISE{
    text-align: end;
    color: #F8C8B5;
    height: 10vh;
    padding-inline-end: 2rem;
    font-family: Song Myung;
    font-size: 3.5vw;
    font-weight: 400;
    line-height: 171.5%;
    letter-spacing: 0.748px;
}
.TREATISE-text{
    color: #FFF;
   
    font-size: 3.5vw;
    padding-bottom: 2vw;
    font-style: normal;
    width: 38vw;
    font-weight: 500;
    line-height: 117%;
    letter-spacing: 0.96px;
    text-transform: uppercase;
}
.our-solutions{
    display: flex;
    align-items: center;
    border-top: 2px dotted #FFF;
    flex-direction: row;
    padding-top: 3vh;
    width: 34vw;
    justify-content: space-between;
}
.tre-our-text{
    color: #C0A99D;
    font-family: Song Myung;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 117%;
    letter-spacing: 0.24px;
    text-transform: capitalize;
}
.we-provide{
    color: #C0A99D;
font-family: Song Myung;
font-size: 1.5vw;
font-style: normal;
font-weight: 400;
letter-spacing: 0.24px;
text-transform: capitalize;
}
.dive-into{
    color: var(--tir, #F1EBCF);
    font-family: Poppins;
    font-size: 1.5vw;
    font-style: normal;
    width: 36vw;
    padding-top: 8vh;
    height: 20vh;
    font-weight: 500;
    line-height: 171.5%;
    letter-spacing: 0.3px;
}
.explore-btn {
    text-align: start;
    padding-top: 22vh;
    }
    .treatise{
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 1.2vw;
        padding: 10px 25px;
        border: 1.188px solid #FFF;
        background: var(--sec, #622E27);
        font-style: normal;
        font-weight: 500;
        line-height: 171.5%;
        letter-spacing: 0.385px;
    }
    .T-con-text {
        width: 100%;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
    }
   
    @media screen and (max-width: 960px) {
        .Tre-container {
            background: #622E27;
            display: flex;
            padding: 20px;
            width: auto;
            height: auto;
            flex-direction: column;
        }
        .main {
            display: none;
        }
        /* .TREATISE {
            text-align: start;
            color: #F8C8B5;
            padding-left: 2rem;
            width: 100%;
            height: auto;
            font-family: Song Myung;
            font-size: 2rem;
            font-weight: 400;
            line-height: 171.5%;
            letter-spacing: 0.748px;
        } */
        .T-con-text {
            width: 100%;
            padding-left: 2vw;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
        }
        .TREATISE-text {
            color: #FFF;
            font-family: Song Myung;
            font-size: 1.5rem;
            padding-bottom: 2vw;
            font-style: normal;
            width: auto;
            font-weight: 400;
            line-height: 117%;
            letter-spacing: 0.96px;
            text-transform: uppercase;
        }
        .our-solutions {
            display: flex;
            align-items: center;
            border-top: 2px dotted #FFF;
            flex-direction: column;
            margin-top: 2vh;
            padding-top: 2vh;
            gap: 1rem;
            width: auto;
            justify-content: space-between;
        }
        .tre-our-text {
            color: #C0A99D;
            font-family: Song Myung;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 117%;
            letter-spacing: 0.24px;
            text-transform: capitalize;
        }
        .we-provide {
            color: #C0A99D;
            font-family: Song Myung;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.24px;
            text-transform: capitalize;
        }
        .dive-into {
            color: var(--tir, #F1EBCF);
            font-family: Poppins;
            font-size: 1.1rem;
            font-style: normal;
            text-align: center;
            width: auto;
            padding-top: 4vh;
            height: 20vh;
            font-weight: 500;
            line-height: 171.5%;
            letter-spacing: 0.3px;
        }
        .explore-btn {
            text-align: center;
            padding-bottom: 1rem;
            padding-top: 1vh;
        }
        .expo-arrow {
            width: 2rem;
            padding-left: 1rem;
        }
        .treatise {
            color: #FFF;
            text-align: center;
            font-family: Poppins;
            font-size: 1.2rem;
            padding: 10px 25px;
            border: 1.188px solid #FFF;
            background: var(--sec, #622E27);
            font-style: normal;
            font-weight: 500;
            line-height: 171.5%;
            letter-spacing: 0.385px;
        }
        .treatise-container {
            display: flex;
            height: auto;
            justify-content: space-evenly;
        }
    }


@media screen and (max-width:820px) {
    .TREATISE-text{
        font-size: 3rem;
    }
    .TREATISE{
        font-size: 5.5vw;
    }
    :is(.we-provide ,.tre-our-text){
        font-size: 2rem;
    }
    .our-solutions{
        align-items: flex-start;
    }
    .dive-into{
        font-size: 1.5rem;
        text-align: start;
    }
}
@media screen and (max-width:540px){
    .TREATISE-text{
        font-size: 2rem;
    }
   
    :is(.we-provide ,.tre-our-text){
        font-size: 1.5rem;
    }
  
    .dive-into{
        font-size: 1.2rem;
        text-align: start;
    }
    .T-con-text{
        gap: 1.5rem;
    }
}
