
.mid-heading {
  text-align: center;
  padding: 40px 0;
  font-family: satochi;
}
.mid-heading h1 {
  font-size: 45px;
  margin: 20px 0;
  text-transform: capitalize;
  color: #06316a;
  font-family: satochi;
}
.mid-heading h3 {
  font-weight: 600;
  color: #06316a;
  text-transform: uppercase;
  font-family: satochi;
  font-size: 24px;
}
   .faq{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center ;
   }
    .faq .container-2 {
       width: 70%;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content:center ;
    }
    .box-faqbox{
      margin-bottom: 20px;  
    }
    .faq button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 15px 20px;
      border: none;
    }
    .faq h1 {
      font-weight: 500;
      font-family: satochi;
      font-size: 18px;
    }
    .sub-title-faq{
      text-align: start;
      width: 100%;
      font-weight: 500;
      color: #000;
      font-family: satochi;
      padding: 1rem;
      font-size: 1.2rem;
    }
    .faq li {
      padding: 1rem;
      width: 60vw;
      border-radius: 5px;
      color: #000;
    }
    :is(.titen,.faq .accordion):hover{
      background: var(--c1, linear-gradient(132deg, #C24609 18.86%, #EE9277 67.45%, #CD5924 99.88%));
      transition: 0.5s;
      color: #fff;
      border: none;
    }
    .text-faqus {
      top: -200%;
      font-size: 1.2rem;
      display: flex;
      background-color: rgb(244, 244, 244);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      font-family: 'Playfair Display', serif;
  }
  @media screen and (max-width:720px) {
    .pricingitem {
       flex-wrap: wrap;
     }
  }
  .faq-1{
    text-align: start;
  }