.Beginner-container{
    width: 100%;
    height: auto;
    background-color: #ffedea;
}

.Beginner-nav{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 2rem;
    height: 10vh;
    background-color: #F1EBCF;
}
.Beginner-mid-container{
    width: 100%;
    height: 16vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #622E27;
}
.Beginner-mid-container h1{
color: #FFF;
font-family: Song Myung;
font-size:clamp(1.0rem , 3vw , 3rem);
font-style: normal;
font-weight: 400;
letter-spacing: 0.045rem;
}
.world-2{
   
    width: 5.44113rem;
    height: 5.97444rem;
    position: relative;
    top: -18px;
}
.doller-2{
width: 2.69519rem;
height: 2.87819rem;
position: relative;
top:18px
}
.textbtn-contain {
    padding-inline: 3em;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: end;
}
.test-enable {
    width: 148px;
    height: 32px;
    font-size: 1em;
    font-weight: 400;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #C64709;
    color: #FFF;
}

.Beginner-body-contain{
    width: auto;
    padding-inline: 3rem;
    padding-block: 1.5rem;
    gap: 2rem;
    display: flex;
    flex-direction: column;
}
.Beginner-body-contain::-webkit-scrollbar{
    width: 0;
}
.Beginner-body-1{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:0.5rem
}
.Beginner-body-1 p{
    color: #C68A6D;
font-family: Poppins;
font-size: 1.04569rem;
font-style: normal;
font-weight: 400;
}
.Beginner-body-1 span{
    color: var(--pri, #C64709);
font-family: Poppins;
font-size: 1.25rem;
font-style: normal;
font-weight: 400;

}
.Beginner-body-2{
    width: auto;
    display: flex;
    flex-direction: column;
    height: 60vh;
    border: 1px solid var(--pri, #C64709);
}

.Beginner-body-left img{
    width: 40vw;
    object-fit: cover;
    height: 45vh;
    padding-inline:1rem;
   
}
.Beginner-body-right{
    width: 100%;
   
}
.Beginner-pora {
    display: flex;
    flex-direction: row;
    gap:1rem;
    height: 5vh;
    padding:4px;
    align-items: center;
    justify-content: flex-end;
}
.Beginner-pora img {
    height: 3vh;
}
.Beginner-pora p{
    color: var(--sec, #622E27);
    font-family: Poppins;
    font-size: 1.025rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.Beginner-body{
    display: flex;
}
.Beginner-body-left {
    width: 100%;
  
}
.Beginner-right-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.5rem;
    height: 48vh;
}
.plans-soon{
  display: flex;
  align-items: center;  
  height: 100%;
  width: 100%;
  justify-content: center;

}
.commin-zoon{
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
}
.comming{
    position: absolute;
    display: flex;
    height: 87vh;
    filter: none;
    pointer-events: auto;
    opacity: 1;
    width: 90%;
    font-weight: bold;
    -webkit-text-decoration: double;
    text-decoration: double;
    align-items: center;
    justify-content: center;
}

.soon{
    font-size: 4.2rem;
    font-weight: bold;
    text-align: center;
    filter: none;
    align-items: center;
    position: relative;
    height: 100%;
    justify-content: center;
    pointer-events: auto;
    display: flex;
    width: 100%;
    opacity: 1;
}

.blur {
    filter: blur(3px);
  }
  .disabled {
    pointer-events: none;
    opacity: 0.5; 
  }
  


.Beginner-right-title h1{
    color: var(--sec, #622E27);
    font-family: Song Myung;
    font-size: clamp(1.5rem ,2vw + 1rem ,2.5rem);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.0375rem;
}
button.rgt-btn-01 {
    padding: 5px 20px 5px 20px;
    background-color: #622e27;
    color: white;
    border: none;
    border-radius: 3px;
}
.Beginner-right-title p{
color: #000;
font-family: Poppins;
font-size: 1rem;
width: 90%;
text-align: justify;
font-style: normal;
font-weight: 400;
}

.beginner-right-button-p{
 width: 12.19031rem;
color: #FFF;
font-family: Poppins;
font-size: 1.01588rem;
font-style: normal;
cursor: pointer;
font-weight: 500;
border-radius: 0.47406rem;
background: var(--pri, #C64709);
padding: 0.47406rem 4.00281rem 0.51625rem 4.0625rem;
}
.plans-price-span {
    border-radius: 0.1875rem;
    border: 1px solid #FFD13D;
    height:2.3rem;
padding: 0.3125rem 1.5625rem;
    color: #000;
font-family: Inter;
font-size: 1.5rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.test-btn {
    background: #622e27;
    padding: 7px;
    font-family: 'Roboto';
    color: white;
    border-radius: 5px;
    cursor: pointer;
    border: none;
}

.Beginner-right-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:2rem
}
.Beginner-videocount h1{
    color: #000;
    font-family: Song Myung;
    font-size: 1.62763rem;
    font-style: normal;
    font-weight: 400;
   
}
.Beginner-body-3 {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: auto;
    align-items: flex-start;

}
.Beginner-body-scrol {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: auto;
    pointer-events: scroll;
    opacity: 0.5;
    align-items: flex-start;
    filter: blur(3px);
}
.Beginner-video-countain {
    display: flex;
    width: 100%;
    height: 25vh;
    padding: 1rem;
    border-radius: 0.28975rem;
    background: #D9632A;
    align-items: center;
    justify-content: space-around;
}
.Beginner-video-countain h1{
color: #FFF;
font-family: Poppins;
font-size: 1.08663rem;
width: 180px;
font-style: normal;
font-weight: 400;
}
.beginner-duration p{
color: #FFF;
font-family: Poppins;
font-size: 1.08663rem;
font-style: normal;
font-weight: 400;

}
.beignner-videoplayer button {
    background: transparent;
    border: none;
}
.beignner-videoplayer p{
color: #FFC300;
font-family: Poppins;
font-size: 1.08663rem;
font-style: normal;
font-weight: 400;

}
.video-img{
    width: 25vw;
    height: 20vh;
    object-fit: cover;
}
.beginner-duration {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.beignner-videoplayer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
/* @media screen and (min-height: 768px) {
    .Beginner-right-title h1{
        font-size: clamp(1.5rem ,2vw + 1rem ,3rem);
    }
    .Beginner-right-title p{
        font-size: 1rem;
        padding: 0.4rem;
    }
    .Beginner-body-2 {
        width: auto;
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .Beginner-body {
        display: flex;
        flex-direction: column;
    }
    .Beginner-body-left img {
        width: 76vw;
        object-fit: cover;
        height: auto;
       
    }
    .Beginner-right-button {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
    .Beginner-video-countain {
        height: auto;
        display: flex;
        flex-direction: column;
    }
    .video-img {
        height: auto;
        width: 100%;
    }
} */