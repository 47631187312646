@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

.popup-main {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  justify-content: center;
}
form.Reset-main {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popups-container {
  width: 480.67px;
  gap: 0.5rem;
  padding: 2rem;
  height: 334.09px;
  display: flex;
  border-radius: 0.80406rem;
  background: #2F2A19;
  flex-direction: column;
}

.popup-header p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 17.1056px;
  line-height: 26px;
  width: 336.98px;
  height: 52px;
  color: #FFFFFF;
}

.popup-header {
  display: flex;
  justify-content: center;
 
}

.popup-btn1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
  gap: 12px;
  color: #FFFFFF;
}

.popup-button1 {
  background-color: transparent;
  border: 1px solid #FFFFFF;
  border-radius: 4.47073px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10.2188px;
  line-height: 15px;
  text-align: center;
  color: #FFFFFF;
  padding: 4px 9px 4px 9px;
}

.popup-button2 {
  background: #D04611;
  border: none;
  padding: 12px 48px 12px 48px;
  border-radius: 4.47073px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16.2188px;
  width: 100%;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
}

.popup-btn2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  width: 100%;
  height:auto;

}


@media screen and (max-width: 768px) {
  .popups-container {
    width: 90%;
    height: auto;
   
    left: 5%;
  }

  .popup-header p {
    font-size: 14px;
    width: auto;
    height: auto;
    padding: 0 10px;
  }

  .popup-button1,
  .popup-button2 {
    font-size: 9px;
  }

  .popup-btn2 {
    margin-right: 10px;
    margin-bottom: 2rem;
  }
}



















@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
.reset-btn{
    padding: 14px 102px 10px -2px;
    width: 199.84px;
    height: 38.35px;
    padding: 11px 0px 10px 0px;
    display: flex;
    align-items: center;
    border: none;
    background-color: #FFDB65;
    justify-content: center;
    border-radius: 8.77315px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    color: black;
    font-size: 17px;
    margin-top: 3rem;
}
.forget-heading-2 {
    display: none;
}

.forget-heading {
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 24.2227px;
}
.reset-p {
    font-family: 'Poppins';
    color: #142b7f;
    width: 20rem;
    font-size: 12.8292px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    text-align: center;
 
}
.content-reset {
    font-family: 'poppins';
    margin-top: 1rem;
   font-style: normal;
   font-weight: 400;
   font-size: 11.9739px;
    line-height: 18px;



color: #000000;
   
}
.content-reset a {
    text-decoration: none;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 11.9739px;
line-height: 18px;


color: #474BB5;
}
.reset-input {
   
  height: 563.77px;
  width: 794.15px;
  display: flex;
  background: var(--3, #F1EBCF);
  box-shadow: 2.53994px 1.69329px 11.85305px 1.69329px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  align-items: center;
  /* margin-left: 35rem; */
  justify-content: center;
    
}

@media screen and (max-width: 825px) {
    .reset-input {
        /* margin-left: 1rem; */
        height: 75vh;
    }
    .reset-input { 

      display: flex;
      align-items: center;
      justify-content:center;
    }

    .reset-p {
        width: 12rem;
        margin-top: 10rem;
    }

    .forget-heading {
        display: none;
        font-size: 18px;
    }
    
    .forget-heading-2 {
        font-size: 18px;
        display: flex;
        width: 100%;
        justify-content: center;
        color: white;
        top: 11rem;
        position: absolute;
      
        font-family: 'poppins';
    }

    .reset-btn {
        width: 250px;
        padding: 11px 0px 10px 0px;
        font-size: 14px;
    }
    .Reset-main{
        width: 100%;
        height: 100vh;
     
    }
}