.crash-container{
    background-color: var(--backgroudcolor);
    padding: 1em;
    height: auto;
    display: flex;
    flex-direction: column;
    gap:2em;
}
.crash-container::-webkit-scrollbar{
    width: 0;
}
.arrow-forward{
    display: flex;
    justify-content: center;
    align-items: center;
}
.CrashCurriculum {
    display: flex;
    gap: 1vw;
    height: 10vh;
    align-items: center;
}
.Curriculum {
    color: #C68A6D;
    font-family: Poppins;
    font-size: 16.766px;
    font-style: normal;
    font-weight: 400;
    line-height: 209%;
}
.Hyperledger {
    color: var(--pri, #C64709);
    font-family: Poppins;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 209%;
}
.Curriculum {
    color: #C68A6D;
    font-family: Poppins;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 209%;
}
.crash-Blockchain {
    color: #000;
    font-family: Poppins;
    width: 90vw;
    text-wrap: pretty;
    font-size: 20.042px;
    font-style: normal;
    font-weight: 400;
    line-height: 209%;
}
.crash-Blockchain-image {
    width: 51vw;
}
.crash-Blockchain-image-C{
    display: flex;
    align-items: center;
    padding-top: 1rem;
    justify-content: center;

}
.CrashCurriculum-C {
    height: 38vh;
}
.CrashCurriculum-Cont{
    height: auto;
}
@media screen and (max-width: 468px)  {
    .Curriculum {
        font-size: 1rem;
    }  
}