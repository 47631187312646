@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');




.reset-main {

  width: 100%;
display: flex;
justify-content: center;
align-items: center;
  height: 100vh;
  flex-direction: column;



 

}




.reset-form {

  display: flex;

  flex-direction: column;

  width: 562px;

  height: 527px;
  background: var(--3, #F1EBCF);
box-shadow: 2.53354px 1.68903px 11.8232px 1.68903px rgba(0, 0, 0, 0.25);

  gap: 2rem;

  justify-content: center;

  align-items: center;





 

}




.reset-password-input {

  display: flex;

  flex-direction: column;

}




.Reset-btn {

  width: 307.05px;

  height: 38.49px;

  display: flex;

  background: #FFD85B;

  border-radius: 8.07424px;

  justify-content: center;

  align-items: center;

  border: none;

}




.reset-heading p {

  color: var(--s, #622E27);
  font-family: Song Myung;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}




.Reset-details {

  font-family: 'Poppins';

  font-style: normal;

  font-weight: 400;
width: 30vw;
  font-size: 12px;

  line-height: 18px;

}




/* Responsive Styles */




@media only screen and (max-width: 769px) {

  /* Styles for tablets and smaller screens */

  .reset-form {

    width: 90%;

    height: 73vh;

    padding-left: 2rem;

    margin-left: auto;

    margin-right: auto;

  }

 

  .reset-heading {

    font-size: 20px;

    width: 100%;

    display: flex;

    justify-content: center;

    align-items: end;

    height: 29vh;

    line-height: 30px;

  }

 


}




@media only screen and (max-width: 480px) {

  /* Styles for mobile devices */

  .reset-form {

    width: 90%;

    padding-left: 1rem;

    margin-left: auto;

    margin-right: auto;

  }

 

  .reset-heading {

    font-size: 18px;

  padding: 2rem;

  display: flex;

  justify-content: center;

  width: 100%;

  align-items: flex-end;

  line-height: 25px;

  height: 22vh;

  }

}
