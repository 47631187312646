

.edit-top{
    width: 100%;
    height: auto;
    background:#F1EBCF;
    display: flex;
    flex-direction: column;
   
}

.back-arrow{
    width: auto;
    height: 25vh;
    padding-inline: 2rem;
    padding-block: 1rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    background: var(--sqas-q, linear-gradient(1deg, #CA541A 6.87%, rgba(232, 94, 50, 0.68) 57.41%, #EA8765 67.28%, rgba(163, 65, 24, 0.72) 100%));
    background-blend-mode: darken;
}
.svg{
    font-size:30px;
    margin-top: 8px;
}
.arrow-left{
    display: flex;
    flex-direction: row;
    gap:10px;
    align-items: center;
     justify-content: flex-start;
}
.arrow-left h3{
    font-style: normal;
    font-weight: 400;
    font-size: 33.792px;
    line-height: 41px;
    font-family: 'Poppins';
    color: #000000;
}
.arrow-mid {
   
    display: flex;
    height: 20vh;
    padding-inline: 2rem;
    padding-block: 1rem;
    align-items: flex-end;
    color: var(--tir, #F1EBCF);
font-family:'Poppins';
font-size: 3rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.back-to-home{
    display: flex;
    align-items: center;
    padding: 0.1875rem 0.5625rem 0.1875rem 0.8125rem;
justify-content: flex-end;
align-items: center;
gap: 0.375rem;
border-radius: 0.25rem;
background: var(--sec, #622E27);
color: #FFF ;
font-family: Poppins;
font-size: 1.05206rem;
font-style: normal;
font-weight: 300;
line-height: normal;
}
.arrow-right h3 {
    display: flex;
    width: 6.52294rem;
    height: 2.15675rem;
    padding: 0.30363rem 1.17231rem 0.35313rem 1.22556rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: rgba(255, 255, 255, 0.90);
font-family: Poppins;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: uppercase;
border-radius: 0.31563rem;
background: var(--sec, #622E27);
}
.edit-btm{
    width: 100%;
    height: 50vh;
}
.edit{
    width: 100%;
    background-color: rgb(231, 231, 255);
    height: 15vh;
    display: flex;
    align-items: flex-end;
    padding-inline: 4rem;
    justify-content: center;
}
.edit-pitcure{
    border-radius: 50%;

}
.edits{
    width: 100%;
    background-color: rgb(231, 231, 255);
  
    display: flex;
    align-items: center;
    justify-content: center;
}
.edit-form img{
    position: relative;
    display: flex;
    background-color: #efefef7d;
    flex-direction: column;
    justify-content: center;
    height:30vh;
    width:30vh;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    
}
.select-delet{
    display: flex;
    flex-direction: column;
    gap:1.67rem
}
.select-btn{
padding: 0.23931rem 1.92425rem 0.23356rem 1.59544rem;
align-items: center;
border-radius: 0.31906rem;
background: var(--sec, #622E27);
color: #FFF;
font-family: Poppins;
font-size: 1.34281rem;
font-style: normal;
font-weight: 300;
line-height: normal;
}
.delete-btn{
padding: 0.23931rem 1.03694rem 0.23356rem 1.43581rem;
justify-content: flex-end;
align-items: center;
gap: 1.135rem;
border-radius: 0.31906rem;
border: 1.276px solid #000;
color: var(--sec, #622E27);
font-family: Poppins;
font-size: 1.34281rem;
font-style: normal;
font-weight: 300;
line-height: normal;
}
.edit-name-photo {
    display: flex;
    height: auto;
    padding-inline: 4rem;
    padding-block-start: 2rem;
    align-items: center;
    justify-content: space-between;
}
.edit-name-photo-left {
    display: flex;
    align-items: center;
    margin-right:10rem;
}
.edit-name-photo-right{
    display: flex;
width: 15.9375rem;
padding: 1.125rem 0.91238rem 3.66769rem 0.875rem;
flex-direction: column;
align-items: center;
gap: -0.01406rem;
border-radius: 2.9375rem;
background: #DB6D40;
color: #EDE7E7;
text-align: center;
font-family: Poppins;
font-size: 0.79438rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.form-1{
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
}
.form-2{
    width: 100%;
     display: flex;
     flex-direction: column;
     height: auto;
     align-items: flex-start;
     justify-content: space-around;
}
.form-container-1{
    display: flex;
    align-items: center;
    width: auto;
    padding-left: 4rem;
    height: 25vh;
    gap:4.44rem;
    flex-direction: row;
}
.form-name{
    display: flex;
    gap:1rem;
    flex-direction: column;
}
.first-Name{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 23.232px;
line-height: 35px;

color: #000000;
}
.profile-details-btn{
width: 135.77px;
height: 34.12px;
background: #F1BA53;
border-radius: 6.336px;
border: none; 
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 21.12px;
line-height: 32px;
/* identical to box height */


color: #FFFFFF;
}
.input-name{
   
    background-color: transparent;
    padding: 8px;
    border: 1px solid var(--pri, #C64709);
    width: 244.99px;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 27px;

color: #1D0F2E;
}
.input-name-1{
    background-color: transparent;
    border: 1px solid var(--pri, #C64709);
    width: 404.47px;
    padding: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #1D0F2E;
}
.password-change{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    gap:1.56rem
}
.password-change p{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 29.568px;
line-height: 44px;

color: #010C0E;

}
.password-input{
    display:flex;
    flex-direction: column;
    gap:1rem;
    font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 21.12px;
line-height: 32px;

color: #000000;
}
.pass-btn-save{
    margin-top: 2em;
}
.password-change-btn{
    display: flex;
    padding: 0.4375rem 2.41444rem 0.43844rem 2.4375rem;
    justify-content: center;
    align-items: center;
border-radius: 0.25rem;
border: 1.056px solid #FFF;
background: var(--sec, #622E27);
color: #FFF;
font-family: Poppins;
font-size: 1.125rem;
font-style: normal;
font-weight: 400;
line-height: normal;

}

.form-container-2{
    display: flex;
    width: auto;
    padding-left: 4rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;
}
.currentpassword{
    display: flex;
    flex-direction: column;
    font-family: 'Poppins';
    color: black;
    font-weight: 300;
    font-size: 20px;
    }
.img{
    width:25vh;
    height: 25vh;
    object-fit: cover;
    border-radius: 50%; 
}

 .celebrity{
    width: 100%;
    
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} 
.edit-picback{
    position: absolute;
    width: 100%;
    height: 10vh;
    margin-top: 15vh;
   background: rgba(225, 221, 219, 0.25);
}
.edit-name{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-block-end: 4rem;
}

.edit-input{
    padding: 12px;
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgb(11, 10, 10);
    border-left: none;
    background-color: transparent;
    width: 100%;
    border-radius: none;
}

.edit-input1{
    padding: 12px;
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgb(11, 10, 10);
    border-left: none;
    background-color: transparent;
width:500px;
    border-radius: none;
}
.edit-btn{
    width: 120px;
    height: 40px;
    background: #16463F;
border: 1px solid #140202;
border-radius: 6px;
}

.edit-sumbit{
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    
    color: #FFFFFF; 
}
.edit-btm{
    width: 100%;
    height: 50vh;
    padding: 20px;
   
}
.password-top{
    width: 100%;
    height: 10vh;
   
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.passwordcontainer-left{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 28px;
margin-left: 12px;
color: #010C0E;
}
.update-password{
  
width: 200px;
height: 40px;
background: #16463F;
border: 1px solid #FFFFFF;
border-radius: 6px;
}
.update-password p{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
color: #FFFFFF;
}
.alert{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
display: flex;
color: #000000;
}

@media screen and (max-width:820px){
    .edit{
        height: 12vh;
    }
    .edit-name-photo{
        flex-direction: column;
        gap:2rem;
    }
    .edit-form img{
        height: 25vh;
        width: 25vh;
    }
    .form-line{
        height: 0;
    }
    .form-1{
        height: 20vh;
    }
    .back-arrow{
        height: 20vh;
    }
    .edit-name-photo-left{
        margin: 0;
    }
}
@media screen and (max-width:620px){
    .arrow-left h3{
        font-size: 24.792px;
    }
    .arrow-mid{
        padding-inline: 0;
        font-size: 2rem;
    }
    .arrow-right h3{
        font-size: 18px;
        margin-top: 5px;
    }
    .back-arrow {
        height: 25vh;
    }
    .edit{
        height: 8vh;
    }
    .edit-form img{
        height: 20vh;
        width: 20vh;
    }
    .form-1{
        height: 45vh;
    }
    .form-container-1 {
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
    }
    .form-line {
        margin-top: 5vh;
    }
    .form-container-2 {
        flex-direction: column;
    }
    .form-2{
        height: 75vh;
    }
}
@media screen and (max-width:420px) {
    .arrow-left h3 {
        font-size: 16.792px;
    }
    .input-name-1{
        width: 325px;
    }
    .arrow-right h3 {
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
    }
    input.input-name-1 {
        width: 300px;
    }
    .back-arrow {
        gap: 12px;
        padding: 8px;
    }
    .edit-name-photo-left{
        gap: 0;
    }
    .edit-name {
       
        gap: 4rem;
        padding-block-end: 2rem;
    }
    .back-to-home{
     padding: 0;
    }
    .arrow-mid{
        font-size: 1.3rem;
    }
}