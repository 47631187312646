

.brd {
    border: 2px solid #090a0a;
   
  }
  
  #select-box {
    width: 500px;
  }
  
  #select-button {
    position: relative;
    height: 5vh;
    display: flex;
    padding-inline: 1rem;
    width: 356px;
    border: 1px solid black;
    padding-block: 1rem;
    width: 239px;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;

  }
  #select-button1 {
    position: relative;
    height: 5vh;
    display: flex;
    padding-inline: 1rem;
    width: 239px;
    border: 1px solid black;
    padding-block: 1rem;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;

  }
  
  #options-view-button {
   
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }
  
  #selected-value {
    font-size: 16px;
    line-height: 1;
    margin-right: 26px;
  }
  
  .option i {
    width: 16px;
    height: 16px;
  }
  
  .option,
  .label {
    color: #2d3667;
    font-size: 16px;
  }
  
  #chevrons {
    position: absolute;
    left: 175px;
    width: 12px;
   
  }
  
  #chevrons i {
    display: block;
    height: 50%;
    color: #d1dede;
    font-size: 12px;
    text-align: right;
  }
  
  #options-view-button:checked + #select-button #chevrons i {
    color: #2d3667;
  }
  
  .options {
    position: absolute;
    left: 0;
    width: 250px;
  }
  
  #options-view-button:checked ~ #options {
    border: 1px solid #e2eded;
    border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
    z-index: 1;
    position: absolute;
    display: flex;
    width: 500px;
    background-color: white;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  
  .option {
    position: relative;
    line-height: 1;
    transition: 0.3s ease all;
    z-index: 2;
    width: 100%;
  }
  
  .option i {
    position: absolute;
    left: 14px;
    padding: 0;
    display: none;
  }
  
  #options-view-button:checked ~ #options .option i {
    display: block;
    padding: 12px 0;
  }
  
  .label {
    display: none;
    padding: 0;
    margin-left: 27px;
  }
  
  #options-view-button:checked ~ #options .label {
    display: block;
    padding: 12px 14px;
  }
  
  .s-c {
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
  }
  
  .s-c.top {
    top: 0;
  }
  
  .s-c.bottom {
    bottom: 0;
  }
  
  .s-c[type="radio"] {
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    height: 50%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }
  
  .s-c:hover ~ i {
    color: #000000;
    opacity: 0;
  }
  
  .s-c:hover {
    height: 100%;
    z-index: 1;
  }
  
  .s-c.bottom:hover + i {
    bottom: -25px;
    animation: moveup 0.3s ease 0.1s forwards;
  }
  
  .s-c.top:hover ~ i {
    top: -25px;
    animation: movedown 0.3s ease 0.1s forwards;
  }
  
  @keyframes moveup {
    0% {
      bottom: -25px;
      opacity: 0;
    }
    100% {
      bottom: 0;
      opacity: 1;
    }
  }
  
  @keyframes movedown {
    0% {
      top: -25px;
      opacity: 0;
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }
  
  .label {
    transition: 0.3s ease all;
  }
  
  .opt-val {
    position: absolute;
    left: 14px;
    width: 217px;
    height: 21px;
    opacity: 0;
    background-color: #fff;
    transform: scale(0);
  }
  
  .option input[type="radio"]:checked ~ .opt-val {
    opacity: 1;
    transform: scale(1);
  }
  
  .option input[type="radio"]:checked ~ i {
    top: 0;
    bottom: auto;
    opacity: 1;
    animation: unset;
  }
  
  .option input[type="radio"]:checked ~ i,
  .option input[type="radio"]:checked ~ .label {
    color: #fff;
  }
  
  .option input[type="radio"]:checked ~ .label:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  #options-view-button:not(:checked)
    ~ #options
    .option
    input[type="radio"]:checked
    ~ .opt-val {
    top: -30px;
  } 
  .option:nth-child(1) input[type="radio"]:checked ~ .label:before {
    background-color: #ceedf7;
    border-radius: 4px 4px 0 0;
    width: 100%;
  }
  .option:nth-child(1) input[type="radio"]:checked ~ .opt-val {
    top: -31px;
  }
  .option:nth-child(2) input[type="radio"]:checked ~ .label:before {
    background-color: #ea4c89;
  }
  .option:nth-child(2) input[type="radio"]:checked ~ .opt-val {
    top: -71px;
  }
  .option:nth-child(3) input[type="radio"]:checked ~ .label:before {
    background-color: #0057ff;
  }
  .option:nth-child(3) input[type="radio"]:checked ~ .opt-val {
    top: -111px;
  }
  .option:nth-child(4) input[type="radio"]:checked ~ .label:before {
    background-color: #32c766;
  }
  .option:nth-child(4) input[type="radio"]:checked ~ .opt-val {
    top: -151px;
  }
  .option:nth-child(5) input[type="radio"]:checked ~ .label:before {
    background-color: #f48024;
  }
  .option:nth-child(5) input[type="radio"]:checked ~ .opt-val {
    top: -191px;
  }
  .option:nth-child(6) input[type="radio"]:checked ~ .label:before {
    background-color: #006400;
    border-radius: 0 0 4px 4px;
  }
  .option .fa-codepen {
    color: #000;
  }
  .option .fa-dribbble {
    color: #ea4c89;
  }
  .option .fa-behance {
    color: #0057ff;
  }
  .option .fa-hackerrank {
    color: #32c766;
  }
  
  .option .fa-stack-overflow {
    color: #f48024;
  }
  
  .option .fa-free-code-camp {
    color: #006400;
  }
  
  #options-view-button:checked ~ #options #option-bg {
    display: block;
  }
  
  .option:hover .label {
    color: #000000;
  }
  
  .option:nth-child(1):hover ~ #option-bg {
  
    background-color: #000;
    border-radius: 4px 4px 0 0;
  }
  
  .option:nth-child(2):hover ~ #option-bg {
  
    background-color: #ea4c89;
  }
  
  .option:nth-child(3):hover ~ #option-bg {
  
    background-color: #0057ff;
  }
  
  .option:nth-child(4):hover ~ #option-bg {
  
    background-color: #32c766;
  }
  
  .option:nth-child(5):hover ~ #option-bg {
  
    background-color: #f48024;
  }
  
  .option:nth-child(6):hover ~ #option-bg {
  
    background-color: #006400;
    border-radius: 0 0 4px 4px;
  }
  #app-cover{
    width: 100%;
  }
  .Help-center{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      
  }
  .Tp-container{
      display: flex;
      flex-direction: column;
      height: auto;
  }
  .help-top{
      width: 100%;
      position: fixed;
      z-index: 1;
      height: 80.01px;
      background: #FFFFFF;
      border-bottom: 1.12697px solid #FFFFFF;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-left: 98.05px;
      padding-right: 56.25px;
  }
  .top-first{
      display: flex;
      align-items: center;
      gap:34.68px;
  }
  .top-first p{
    font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 22.5395px;
  line-height: 34px;
  letter-spacing: 0.01em;
  color: #000000;
  }
  .top-first span{
    font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 22.5395px;
  line-height: 34px;
  border-bottom: 1.12697px solid #0D1049;
  letter-spacing: 0.01em;
  
  color: #000000;
  }
  .top-mid img{
      width: 161.16px;
      height: 58.06px;
  }
  .top-end{
      display: flex;
      align-items: center;
      gap:41.32px;
  }
  .top-end p{
    font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 22.5395px;
  line-height: 34px;
  letter-spacing: 0.01em;
  color: #000000;
  }
  .top-end span{
    font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 22.5395px;
  line-height: 34px;
  letter-spacing: 0.01em;
  
  color: #000000;
  }
  .dolor-search{
      display: flex;
  
  }
  .doller-background{
      height: 59.5779282046038px;
  width:  64.382825px;;
  right: 250px;;
  top: 198.04638671875px;
  border-radius: 0px;
  position: absolute;

  background-repeat: no-repeat;
  opacity: 0.5;
  }
  .search-box{
      height: 238.91px;
      background: #413F3F;
      display: flex;
      margin-top:10vh;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
     
  
  }
  .moon-background{
    
      background-repeat: no-repeat;
      position: absolute;
      width: 134.11px;
      height: 138.26px;
      left: 233.29px;
      top: 51.84px;
      opacity: 0.8;
  }
  
  .search-box p{
      font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 36.15px;
  line-height: 54px;
  letter-spacing: 0.01em;
  
  color: #FFFFFF;
  }
  .search-1 {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: flex-end;
      height: 10vh;
      margin-right: 20px;
    }
    
    .search-1__input {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 17.1852px;
      line-height: 26px;
      
      letter-spacing: 0.01em;
      
      color: rgba(0, 0, 0, 0.49);
      padding: 0.7rem 1rem;
      width: 510.52px;
      height: 41.7px;
      left: 467.69px;
      top: 193.84px;
      
      background: #FFFFFF;
      border: 2.25395px solid #FFFFFF;
      border-radius: 5.72839px;
      transition: all ease-in-out .5s;
      margin-right: -2rem;
    }
  
    input.search-1__input::placeholder{
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 17.1852px;
      line-height: 26px;
      letter-spacing: 0.01em;
      
      color: rgba(0, 0, 0, 0.49);
    }
    .search-1__input:hover, .search-1__input:focus {
      box-shadow: 0 0 1em #00000013;
    }
    
    .search-1__input:focus {
      outline: none;
      background-color: #ffffff;
    }
    
    .search-1__input::-webkit-input-placeholder {
      font-weight: 100;
      color: #ccc;
    }
    
    .search-1__input:focus + .search-1__button {
      background-color: #ffffff;
    }
    
    .search-1__button {
      border: none;
      background-color: #ffffff;
      margin-top: .1em;
    }
    
    .search-1__button:hover {
      cursor: pointer;
    }
    
    .search-1__icon {
      height: 1.3em;
      width: 1.3em;
      fill: #b4b4b4;
    }
    
    .main-tp{
        width: 88vw;

        height: auto;
        padding-left: 7rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
  .main-tp h2{
    font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 67px;
  letter-spacing: 0.01em;
  
  color: #1D2061;
  }
  .feedback-title{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  
  }
  .feedback-title p{
    font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 37px;
  /* identical to box height */
  
  letter-spacing: 0.01em;
  
  color: #000000;
  }
  .feedback-comment{
    height: auto;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .feedback-comment textarea{
    font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16.6203px;
  background-color: white;
  letter-spacing: 0.01em;
  height: 40dvh;
  color: #000000;
  
  }
  .feedback-comment textarea{
    padding-block-end: 8rem;
    padding-block-start: 1rem;
    padding-inline-start: 1rem;
    width: 78%;
    border: 1.02585px dashed #000000;
    border-radius: 4px;
    resize: none;
  }
  .feedback-comment textarea::-webkit-scrollbar{
    width: 0;
  }
  .feedback-submit{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4em;
  }
  .feedback-submit p{  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24.6203px;
  line-height: 37px;
  cursor: pointer;
  width: 270.82px;
  padding:0.2rem;
  background: var(--s, #622E27);
  border-radius: 5.12923px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #ffffff;
  }
  .input-comment{
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  .line-feed{
    border: 1.12237px solid #000000;
  }
  .main-bt {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
    height: 95vh;
    width: 100%;
  }
  .custemor-feed::-webkit-scrollbar{
    width: 0;
  }
  .custemor-feed{
    display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   width: 100%;
   height: 85vh;
   overflow-y: scroll;
   align-items: center;
   justify-content: center;
   gap:2rem
  }
  .custemor-feed-title p{
    font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 36.0023px;
  line-height: 54px;
  letter-spacing: 0.01em;
  
  color: #000000;
  }
  @media screen and (max-width: 960px) {
    .main-tp {
      width: 88vw;
      height: auto;
      padding-left: 2rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  }
  #select-box {
    width: auto;
}
#chevrons{
  left: 100px;
}
#select-button {
  position: relative;
  height: 25px;
  display: flex;
  padding-inline: 1rem;
  padding-block: 1rem;
  width: 43vw;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
}
.feedback-comment {
  height: auto;
  padding-top: 2rem;
  display: flex;
  width: auto;
  flex-direction: column;
  gap: 2rem;
}
.feedback-submit p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24.6203px;
  line-height: 37px;
  cursor: pointer;
  width: 10rem;
  padding: 0.2rem;
  background: var(--s, #622E27);
  border-radius: 5.12923px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #ffffff;
}
    }



    .popup-feed-container {
      display: flex;
      align-items: center;
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
       top: 0;
      width: 100%;
      z-index: 1;
      height: 100%;
      justify-content: center;
  }
  .cancel-feed {
      width: 35px;
      height: 35px;
  }
  .check-feed {
      width: 55px;
      height: 55px;
  }
  .popup-feed-cont {
      border-radius: 24px;
      height: 58vh;
      padding: 1rem;
      width: 48vw;
      background: var(--s, #622E27);
  }
  .cancel-feed-c{
      display: flex;
      width: 100%;
      flex-direction: row-reverse;
      align-items: center;
  }
  .popupfeed-main{
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      height: 48vh;
      flex-direction: column;
  }
  .text-popup {
      color: #FFF;
      font-family: Poppins;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.24px;
  }
  .popup-backhome-btn {
      color: #FFF;
      font-family: Poppins;
      width: 15vw;
      border: none;
      font-size: 1rem;
      border-radius: 5px;
      height: 6vh;
      background: var(--PRI, #C64709);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
  }