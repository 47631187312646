/* styles.css */
.container {
  background-color: #f1ebcf;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 1000.11px;
  height: 618.62px;
}

.sign-up-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.sign-up-container:not(.signinIn) {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
}

.sign-in-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
  left: 0;
  width: 50%;
  z-index: 2;
}

.sign-in-container:not(.signinIn) {
  transform: translateX(100%);
}

.form {
  background: var(--3, #f1ebcf);
  box-shadow: 3.2370412349700928px 2.158027410507202px 15.106191635131836px
    2.158027410507202px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.title {
  font-weight: bold;
  margin: 0;
}

.input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.button {
  border-radius: 20px;
  border: 1px solid #ff4b2b;
  background-color: #ff4b2b;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.button:active {
  transform: scale(0.95);
}

.button:focus {
  outline: none;
}

.ghost-button {
  background-color: transparent;
  border-color: #ffffff;
}

.anchor {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.overlay-container:not(.signinIn) {
  transform: translateX(-100%);
}

.overlay {
  background: linear-gradient
    (
      98deg,
      #c64709 0%,
      rgba(238, 59, 35, 0.96) 66.26%,
      rgba(220, 53, 53, 0.94) 100%
    );
  box-shadow: 3.2370412349700928px 2.158027410507202px 15.106191635131836px
    2.158027410507202px rgba(0, 0, 0, 0.25);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay:not(.signinIn) {
  transform: translateX(50%);
}
.input {
  background-color: #f1ebcf;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  border-bottom: 1px solid;
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}
.left-overlay-panel {
  transform: translateX(-20%);
}
.left-overlay-panel:not(.signinIn) {
  transform: translateX(0);
}
.right-overlay-panel {
  right: 0;
}
.right-overlay-panel:not(.signinIn) {
  transform: translateX(20%);
}

.paragraph {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

/* new style */

.g-btn-01 button {
  color: #fff;

  font-size: 12px;
  font-style: normal;
  /* font-weight: 500; */
  margin-top: 0rem;
  gap: 7px;
  display: flex;
  line-height: normal;
  background-color: #622e27;
  border: none;
  border-radius: 5px;
  padding: 5px;
  align-items: center;
}
.signin-main {
  width: 100%;
  height: 97vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-001 {
  background-size: cover;
  width: 100%;
  height: 96vh;
}
.otp-verification-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.otp-verification {
  width: 480.67px;
  height: 311.32px;
  background: #36362e;
  border-radius: 13.6845px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.otp-verification p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17.1056px;
  line-height: 26px;
  width: 336.98px;
  height: 52px;
  color: #ffffff;
}

.otp-button {
  width: 168.49px;
  padding: 8px;
  background: #ffd85b;
  border-radius: 4.10946px;
  border: none;
}
.otp-place ::placeholder {
  color: #fff;
}
.otp-verification span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10.2188px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 1.6px;
  color: #ffffff;
}
.cancel-img img {
  width: 23px;
  height: 23px;
}
.cancel-img {
  width: 100%;
  padding-inline: 2rem;
  display: flex;
  place-content: end;
}
.resent {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 11.4783px;
  line-height: 17px;
  text-align: center;

  color: rgba(255, 255, 255, 0.95);
}
.resent-otp {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.sen-again {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 9.38599px;
  line-height: 11px;

  border: 0.618184px solid #ffffff;
  border-radius: 3.23137px;
  text-align: center;
  width: 69.28px;
  padding: 4px;
  color: #ffd85b;
}
