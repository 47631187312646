* {
  margin: 0;
  padding: 0;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Song+Myung&display=swap');

:root{
  --backgroudcolor:#FFFCED;
  --buttonsignin:#622E27;
  --get:#C64709;
}

.App{
  display: block;
}
.App::-webkit-scrollbar{
  width: 0;
}

@media (max-width: 767px) {
  .App {
    display: block;
  }
}
html{
  scroll-behavior: smooth;
}
.PrivacyPolicy-main{
  font-family: 'Poppins', sans-serif;
}