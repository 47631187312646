@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;700;800;900&display=swap');
.plancard-contain{
   width: 100%;
   height: 100vh;
   display: flex;
   flex-direction: column;
}
.plancard-title{
   height: 15vh;
   display: flex;
   padding-inline: 5rem;
   align-items: center;
   justify-content: space-between;
   background-color:#fffced;
}
.plancard-title h1{
   color: #000;
font-family: Red Hat Display;
font-size: 34.262px;
font-style: normal;
font-weight: 600;
letter-spacing: 0.514px;
}
.seize{
display: inline-flex;
padding: 2.855px 17.59px 3.145px 21.551px;
justify-content: center;
align-items: center;
gap: 19.449px;
border-radius: 3px;
background: var(--button, linear-gradient(273deg, #ED5412 0.59%, #C53718 100.05%, #C64709 100.06%));
color: white;
}
.seize button{
   color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 17.135px;
font-style: normal;
font-weight: 500;
letter-spacing: 0.257px;
}
.plancard-section{
   display: flex;
   gap:2rem;
   flex-direction: column;
   height: 85vh;
   width: 100%;
   background-color: #fffced;
}
.plancard-top{
   display: flex;
   padding-inline: 5rem;
   height: 10vh;
   align-items: center;
   justify-content:flex-end;
}
.plancard-top-left p{
font-family: 'Poppins';
font-size: 24px;
font-weight: 400;
}
.plancard-top-right{
   display: flex;
   align-items: center;
   gap:2em
}
body .arrow{
    width: 30px;
    height: 30px;
    color: #ffffff;
    border-radius: 50%;
    background-color: rgb(194 70 9);
}
.arrow:hover{
   transition: all 0.3s ease;
   scale: 1.1;
}
.plancard-btm{
    display: flex;
    padding-block: 2rem;
    padding-inline: 1rem;
    gap: 3rem;
    align-items: center;
    justify-content: space-between;
}
.plancard-Cards{
   width: 30vw;
   height: 50vh;
}
.plancard-Cards1{
   width: 60vw;
  
}
.plancard-Card-content {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 1.5rem;
}
.plancard-Card-content1{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
   
}
.plancard-Card{
    gap: 0.8rem;
    cursor: pointer;
    height: 55vh;
    width: 40vw;
    padding: 0.8rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    transition: transform 0.3s ease;
}
.plancard-Card-img img{
   width: 100%;
   height: 75vh;
   object-fit: contain;
}
.plancard-Card-content h2{
   margin: 0;
   font-family: 'Poppins';
   font-size: 2rem;
   font-weight: 500;
}
.plancard-Card-content1 h2{
   text-align: center;
   margin: 0;
   font-family: 'Poppins';
   font-size: 2rem;
   font-weight: 500;
}
.plancard-Card-content p{
   margin: 0;
   text-align: start;
   font-family: 'Poppins';
   font-size: 1.2rem;
   font-weight: 500;
}
.plancard-Card-content1 p{
   text-align: center;
   margin: 0;
   font-family: 'Poppins';
   font-size: 1.2rem;
   font-weight: 500;
}
.plancard-Card-content1 span{
   padding-inline: 1.5rem;
   padding-block: 0.3rem;
   color: white;
   text-align: center;
   border-radius: 0.5rem;
   background-color: rgba(197, 197, 197, 0.808);
   font-weight: 400;
   font-family: 'Poppins';
   font-size: 0.8rem;
}
.plancard-Card-content span{
   padding-inline: 1.5rem;
   padding-block: 0.3rem;
   color: white;
   text-align: center;
   border-radius: 0.5rem;
   background-color: rgba(197, 197, 197, 0.808);
   font-weight: 400;
   font-family: 'Poppins';
   font-size: 0.8rem;
}
.purchases{
   padding: 0.5rem;
   width: 90%;
   color:white;
   text-align: center;
   border-radius: 0.5rem;
   background-color: black;
}
.purchase{
   padding: 0.5rem;
   width: 80%;
   color:white;
   text-align: center;
   border-radius: 0.5rem;
   background-color: black;
}
.purchases p{
   margin: 0;
   font-family: 'Poppins';
   text-align: center;
   font-size: 1rem;
   font-weight: 500;
}
.purchase p{
   margin: 0;
   font-family: 'Poppins';
   text-align: center;
   font-size: 1rem;
   font-weight: 500;
} 

.plancard-Card.active .plancard-Card {
   display: none;

 }
 
 .plancard-Card-another.active .plancard-Card-another {
   display: none;
  
 }
 
 .plancard-Card-another.active {
   background: linear-gradient(137deg, #C24609 28.16%, #FFB0A0 79.51%);
   /* background: linear-gradient(424deg, rgba(98,46,39,1) 0%, rgba(255,252,237,1) 85%); */
   border-radius: 8px;
   border: black;
   color: white;
   transform: scale(1.05);
   transition: transform 0.3s ease;
  
       opacity: 1 !important;
       align-items: center;
       display: flex;
justify-content: center;
   
}
.plancard-Card-another.active .plancard-Card-content span{
   background-color: rgba(255, 255, 255, 0.637);
   color: #000;
}
 
 .active{
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #FFE9DF;
 }
 
 
 @media screen and (max-width:1024px) {
   .plancard-Card{
      width: 35vw;
      height: 55vh;
   }
   .plancard-Card-content1{
      padding: 0;
   }
   :is(.plancard-Card-content1 h2 ,.plancard-Card-content h2){
      font-size: 1.5rem;
   }
   :is(.plancard-Card-content1 p,.plancard-Card-content p){
      font-size: 1rem;
      font-weight: 400;
   }
   .plancard-Card-img img{
      height: 40vh;
   }
   .plancard-btm{
      padding-inline: 2rem;
   }
   .purchases{
      width: 80%;
   }
 }
 
 @media screen and (max-width:820px){
   :is(.plancard-section ,.plancard-contain){
      height: auto;
   }
   .plancard-btm{
      flex-direction: column;
   }
   .plancard-Card {
      width: 65vw;
      height: 37.5vh;
  }
  .plancard-Card-img img {
   height: 25vh;
}
:is(.plancard-Card-content1 h2 ,.plancard-Card-content h2){
   font-size: 1.75rem;
}
:is(.plancard-Card-content1 p,.plancard-Card-content p ,.plancard-Card-content1 span){
   font-size: 1.2rem;
   font-weight: 400;
}
.seize p{
   font-size: 26.135px;
}
.plancard-title{
   padding-block-start: 2rem;
}
.plancard-top{
   height: 5vh;
}
.purchases {
   width: 65%;
}
 }

@media screen and (max-width:540px) {
   .plancard-Card {
      width: 70vw;
      height: 55vh;
  }
  .plancard-title h1{
   font-size: 28.262px;
  }
  .plancard-title{
   padding-inline: 2rem ;
  }
  .seize p {
   font-size: 21.135px;
}
}
@media screen and (max-width:420px) {
   .plancard-Card {
      width: 85vw;
      height: 45vh;
  }
  .plancard-Card-img img {
   height: 50vh;
   object-fit: contain;
}
:is(.plancard-Card-content1 h2 ,.plancard-Card-content h2){
   font-size: 1.5rem;
}
:is(.plancard-Card-content1 p,.plancard-Card-content p ,.plancard-Card-content1 span){
   font-size: 1rem;
   font-weight: 400;
}
}