body{
    margin: 0;
    padding: 0;
}

.feedback-container {
    display: flex;
    width: auto;
    padding: 2rem;
    height: 120vh;
    background: var(--3,#FFFCED);
    align-items: center;
    justify-content: center;
  }
  
  .user-list {
    flex: 2 1;
    padding: 20px;
    display: flex;
    height: 70vh;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    justify-content: space-around;
    
  }
  
  .user-card {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    gap:2rem;
  }
  
  .feedback-details {
    flex: 1 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 5rem;
    gap:1.5rem;
    align-items: flex-start;
    justify-content: center;
   
  }
  .user-list-title {
    width: 100%;
    padding-inline-start: 4rem;
}
.user-list-content {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1rem;
}
  .user-list-title h1{
color: rgba(0, 0, 0, 0.71);
font-family: 'Poppins';
font-size: 34.262px;
font-style: normal;
font-weight: 400;
letter-spacing: 0.514px;
  }
  .feedback-details img {
    /* transform: rotate(180deg); */
    width: 100px;
    height: 100px;
  border-radius: 50%;
}
  .feedback-content{
    padding-inline:2rem;
    display: flex;
    align-items: center;
    gap: 3rem;
    height: 70vh;
    border-radius: 13.706px;
    background: var(--c1, linear-gradient(132deg, #C24609 18.86%, #EE9277 67.45%, #CD5924 99.88%));
    justify-content: center;
    flex-direction: column;
  }
.feedback-content h1{
  text-align: center;
}
 .feedback-content p{
  color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;

font-weight: 500;
line-height: normal;
text-align: center;
 }

  .user-card {
    display: flex;
    width: 85%;
    align-items: center;
    cursor: pointer;
    padding: 1.5rem;
    border-radius: 14.437px;
    border: 0.902px solid var(--PRI, #C64709);
    margin-bottom: 10px;
  }
  
  .user-profile {
    margin-right: 10px;
  }
  
  .user-profile img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .user-details {
    display: flex;
    gap: 0.5rem;
    margin-top:1em;
    flex-direction: column;
   
  }
  .user-details h1{
margin: 0;
font-family: Poppins;
font-size:1rem;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.24px;
  }
  .user-details h3{
margin: 0;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.21px;
  }
  .user-details p{
    margin: 0;
font-family: Poppins;
font-size: 14px;
font-style: normal;

font-weight: 400;
line-height: normal;
letter-spacing: 0.21px;
  }
  .user-card{
    background-color: transparent;
    transition: all 3s ease;
  }
  .user-card.selected {
    border-radius: 14.437px;
    border: 0.902px solid var(--PRI, #C64709);
    background: linear-gradient(138deg, #C24609 26.49%, #EE9277 98.49%, #CD5924 100.15%);
    color: rgb(255, 255, 255);
    transform: scale(1.05);

  }
  .Rectangle img{
 width: 40px;
 height: 40px;

  }
  .Rectangle {
    width: 100%;
    display: flex;

    align-items: center;
    justify-content: flex-start;
}
.Rectangle-1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
  .Rectangle-1 img{
    width: 40px;
    height: 40px;
     }
  @keyframes fadeIn {
    from {
      opacity: 0;
     
     
    }
    to {
      opacity: 1;
     
    }
  }
  
  .fade-in {
    animation: fadeIn 3s ease;
  }
  
  
  @media screen and  (max-width:820px){
    .feedback-container{
      height: auto;
      flex-direction: column;
    }
    .user-list-content{
     align-items: center;
     margin-left: 0;
    }
    .user-list-title {
      padding-inline-start: 6rem;
  }
  .feedback-content{
    height: 50vh;
  }
  .feedback-details{
    align-items: center;
    width: min(80%,100%);
    margin-right: 0;
  }
  
  .user-card{
  width:90%;
  gap:1rem
  }
  }
  @media screen and (max-width:420px){
    .user-card{
      width: 100%;
      gap: 2rem;
      flex-direction: column;
    }
    .user-list-title {
      padding-inline-start: 0;
    }
    .user-details{
      padding: 1rem;
    }
    .feedback-details{
      width: 100%;

    }
    .user-list-content{
      margin-left: 0;
    }
  }
  
