/* Test.css */

.quiz-container {
  display: flex;
  /* max-width: 600px; */
  margin: auto;
  padding: 20px;
  /* border: 1px solid #ccc; */
  /* border-radius: 8px; */
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  align-items: center;
  flex-direction: column;
}
  
  .quiz-options-list {
    list-style-type: none;
    padding: 0;
  }
  
  .quiz-question {
    margin-bottom: 20px;
  }
  
  .quiz-options {
    list-style-type: none;
    padding: 0;
  }
  
  .quiz-option {
    margin-top: 10px;
  }
  
.label-01 {
    display: block;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  
    transition: background-color 0.3s ease;
  }
  
  .test-radio-btn[type="radio"] {
    margin-right: 8px;
   cursor: pointer;
  }
  
  input[type="radio"]:checked + label {
    background-color: #4caf50;
    color: #fff;
    border-color: #4caf50;
  }
  
  .submit-quiz-button {
    background-color: #4caf50;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  .quiz-heading {
    margin-bottom: 1rem;
  }
  p.quiz-question {
    font-size: 20px;
    font-weight: bold;
  }
  
  .submit-quiz-button:hover {
    background-color: #45a049;
  }
  /* TestComponent.css */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.test-popup-content{
  font-size: 18px;
  text-align: center;
}
.skaleton-P{
  display: flex;
  gap: 1rem;
}
.skaleton-C{
  width: 100%;
  margin-top: 2rem;
}
.skaleton{
  width: 100%;
 padding-bottom: 2rem;
}
.popup {
  background: white;
  width: 521px;
  height: 220px;
  padding: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.get-certificate{
  padding: 9px 12px;
  font-size: 18px;
  color: #fff !important;
  border-radius: 5px;
  border: none;
  background-color: #4caf50;
}

.popup-content-test{
  max-width: 400px;
  flex-direction: column;
  width: 100%;
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: center;
}


.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.quiz-retest-btn {
  padding: 7px 26px;
  color: white;
  background-color: #ff5b5b;
  border: none;
}
