@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');


.certificate-main {
    width: 100%;
    background: whitesmoke;
    overflow-y: hidden;
    height: 100vh;
}

.certificate-image {

}
.certificate-content {

}
.certificate-main {
    position: relative;
    
  }
  .dowload-btn{
    display: flex;
    z-index: 11;
    bottom: 68px;
    right: 10vh;
    position: absolute;

  }
  .icons-F{
    color: #fff;
   
  }
  .certificate-text,
  .certificate-image {
    position: absolute;
    width: 100%;
  }
  
  .certificate-text {
    z-index: 2;
    display: flex;
    padding-top: 9rem;
    width: 57vw;
    padding-inline-start: 16rem;
    gap: 1rem;
    /* scroll-padding-inline-start: 1rem; */
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.logo-ino img {
    width: 300px;
    height: auto;
    position: absolute;
    z-index: 2;
}
.logo-inop {
    width: 300px;
    height: auto;
    left: 661px;
    top: 66px;
    font-weight: 500;
    font-family: Poppins;
    color: #595959;
    position: absolute;
    z-index: 2;
}
  
  .certificate-image {
    z-index: 1; /* Place image below the text */
  }
  
  .cer-img img {
    height: 100vh;
    width: 100%;
  }
  .certificate-text h2 {
    color: #C64709;
    font-size:54px;
    font-style: italic;
  }
  .p {
    color: #595959;
    font-size: 27px;
  }
  .recipient-name {
    color: #595959;
    font-size: 27px;
  }
  .recipient-name span {
    color: #1C2143;
    font-size: 62px;
    font-family: "Dancing Script", cursive;
  }
  .recipient-level {
    color: #595959;
    font-size: 27px;
  }
  .recipient-level span {
    color: #1C2143;
    font-size: 35px;
    
  }
  .p-dec {
    color: #595959;
    font-size: 20px;
  }

  @media screen and (max-width:1025px) {
    .certificate-text {
        z-index: 2;
        display: flex;
        padding-top: 9rem;
        width: 72vw;
        padding-inline-start: 13rem;
        gap: 1rem;
        /* scroll-padding-inline-start: 1rem; */
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    .certificate-text h2 {
       
        font-size: 47px;
    }
  }
  @media screen and (max-width: 469px) {
    .certificate-text {
        z-index: 2;
        display: flex;
        /* padding-top: 9rem; */
        width: 72vw;
        padding-inline-start: 1rem;
        gap: 1rem;
        /* scroll-padding-inline-start: 1rem; */
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
  }