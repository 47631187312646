@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&family=Roboto:wght@300;400;500;900&display=swap");


main {
  position: relative;
  width: calc(min(90rem, 90%));
  margin: 0 auto;
  min-height: 100vh;
  column-gap: 3rem;

}

.bg {
  position: fixed;
  top: -4rem;
  left: -12rem;
  z-index: -1;
  opacity: 0;
}

.bg2 {
  position: fixed;
  bottom: -2rem;
  right: -3rem;
  z-index: -1;
  width: 9.375rem;
  opacity: 0;
}

main > div span {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 1rem;
  color: #717171;
}

main > div h1 {
  text-transform: capitalize;
  letter-spacing: 0.8px;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: clamp(2.4375rem, 2.25rem + 0.75vw, 4rem);
  background-color: #C64709;
  background-image: linear-gradient(45deg, #C64709, #000000);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.grab-section-main {
     background-color: #FFFCED;    
}
.grad-section {
    background-color: #FFFCED;    
 
}
main > div hr {
  display: block;
  background: #c76d31;
  height: 0.25rem;
  width: 6.25rem;
  border: none;
  margin: 1.125rem 0 1.875rem 0;
}

main > div p {
  line-height: 1.6;
}

main a {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 500;
  background: #C64709;
  border-radius: 3.125rem;
  transition: 0.3s ease-in-out;
}

main > div > a {
  border: 2px solid #c2c2c2;
  margin-top: 2.188rem;
  padding: 0.625rem 1.875rem;
}

main > div > a:hover {
  border: 0.125rem solid #5a1c00;
  color: #fffced;
}

.swiper {
  width: 100%;
  padding-top: 3.125rem;
}

.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination {
  bottom: 1.25rem !important;
}

.swiper-slide {
  width: 18.75rem;
  height: 28.125rem  !important;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: self-start;
}

.swiper-slide h2 {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.4;
  margin-bottom: 0.625rem;
  padding: 0 0 0 1.563rem;
  text-transform: uppercase;
}

.swiper-slide p {
  color: #dadada;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  padding: 0 1.563rem;
  line-height: 1.6;
 
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.swiper-slide a {
  margin: 1.25rem 1.563rem 3.438rem 1.563rem;
  padding: 0.438em 1.875rem;
  font-size: 0.9rem;
}

.swiper-slide a:hover {
  color: #005baa;
}

.swiper-slide div {
  display: none;
  opacity: 0;
  padding-bottom: 0.625rem;
}

.swiper-slide-active div {
  display: block;
  opacity: 1;
  padding-top: 8rem;
}

.swiper-slide--one {
  /* background: linear-gradient(to top, #0d1518, #203a4300, #3da2ce00), no-repeat 50% 50%/cover; */
background-image: url("../../Images/3d-futuristic-background-with-low-poly-plexus-design.jpg");
  background-size: cover;
 background-position: center;
 border-radius: 1rem;

 
}

/* In a CSS file */

  

.swiper-slide--two {
background-image: url("../../Images/3d-internet-secuirty-badge.jpg");
background-size: cover;
background-position: center;
border-radius: 1rem;
}

.swiper-slide--three {
background-image: url("../../Images/circuit-cyberspace-closeup-with-neon-lights.jpg");
  background-size: cover;
 background-position: center;
 border-radius: 1rem;
}

.swiper-slide--four {
  background-image: url("../../Images/futuristic-hi-tech-neon-background-generative-ai.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 1rem;
}

.swiper-slide--five {
background-image: url("https://img.freepik.com/free-photo/blockchain-technology-background-gradient-blue_53876-124648.jpg?t=st=1693981726~exp=1693982326~hmac=d8961238ddfc779f648fed3b99921b1028d8a5909ac0cc7ea67039890f09a0ee");
background-size: cover;
background-position: center;
border-radius: 1rem;
}

.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right {
  background-image: none;
}

@media screen and (min-width: 48rem) {
  main {
    display: flex;
    align-items: center;
  }

  .bg,
.bg2 {
    opacity: 0.1;
  }
}
@media screen and (min-width: 93.75rem) {
  .swiper {
    width: 85%;
  }
}
@media screen and (max-width: 490px) {
  .grab-section-main {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
  
}