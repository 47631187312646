.text-faq {
  
    color: #1C1C38;
    font-family: Poppins;
    font-size: 1.8rem;
    padding-bottom: 3vh;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.327px;

}
.faq-p {
 
    color: #000;
    font-family: Poppins;
    font-size: 1.2rem;
    font-style: normal;
    width: 28vw;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.225px;

}
.gethelp-container {
    display: flex;
    flex-direction: column;
    padding-left: 15vw;
    gap: 4vh;
    width: 62vw;
    padding-top: 10vh;
}

.faq-btn-C {
    display: flex;
    height: 20vh;
    width: 50vw;
    gap: 4vw;
    align-items: center;
    margin-bottom: 4em;
}
.gethelp-btn{
    border: none;
}
.gethelp-btn-img{
    width: 4vw;
    height: 7vh;
}

@media screen and (max-width: 960px) {
    .gethelp-container {
       height: auto;
    width: auto;
    gap: 9vw;
    align-items: flex-start;
    flex-direction: column;
    }
    .faq-btn-C {
        height: auto;
        width: auto;
        gap: 9vw;
        align-items: flex-start;
        flex-direction: column;
    }
    .faq-p {
        color: #000;
        font-family: Poppins;
        font-size: 1.2rem;
        font-style: normal;
        width: auto;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.225px;
    }
    .gethelp-btn-img {
        width: 3rem;
        height: 3rem;
    }
}