
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

.Article-container-main {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.Article-container{
height: auto;
background-color: antiquewhite;
}
.Article-title h1{
font-size: clamp(1.3rem , 3vw , 3rem);
padding: 0.5rem;
font-family: Fira Sans;
}
.Article-title span{
display: flex;
flex-direction: row;
gap: 0.5rem;
align-items: flex-start;
}
.Article-title{
width: 100%;
display: flex;
margin-top: 2em;
align-items: center;
justify-content: center;
}
.Article-content{
padding-inline: 4rem;
padding-block-start:2rem;
}
.Article-subheading-1{
padding-inline: 5rem;
}
.subheading {
display: flex;
flex-direction: column;
}
:is(.paragraph-1 ,.paragraph-2){
  padding-inline: 4rem;
  padding-block-end: 2rem;
  height: auto;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}
:is(.paragraph-1 h2,.paragraph-2 h2 ,.Article-title-model-2 h2 ,.Article-subheading-1 h2 ){
  color: var(--PRI, #C64709);
  font-family: Fira Sans;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 34.839px; /* 96.774% */
}
.Article-title-model-2 h3{
  color: var(--PRI, #C64709);
  font-family: Fira Sans;
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.839px; /* 96.774% */
}
:is(.paragraph-1 p,.paragraph-2 p ,.Article-title-model-2 p ,.para-1 p ,.para-2 p ,.Article-subheading-1 p,.Article-content p){
  color: #000;
  font-family: Fira Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32.839px; 
  }
:is(.Article-title-model-1 img , .Article-title-model-2 img){
width: min(650px , 100%);
height: auto;
object-fit: cover;
}
.Article-title-model-1{
display: flex;
flex-direction: column;
gap: 2rem;
align-items: center;
padding-inline: 4rem;
padding-block: 2rem;
justify-content: center;
}
.Article-title-model-2 {
display: flex;
flex-direction: column;
gap: 2rem;
padding-inline: 4rem;
padding-block: 2rem;

}
:is(.listitem8 ,.image-2) {
width: 100%;
display: flex;
align-items: center;
justify-content: center;
}
.channel{
    
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textarea-container {
  width: 100%;
  display: flex;
  margin-block: 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
}

.textarea-getArticleText {
  height: 80vh;
  overflow-x: scroll;
  overflow-y: scroll;
  width:min(70vw ,100%);
  background-color: #9d9d9de1;
  padding: 10px;
  font-size: 18px;
  font-family:Fira Sans ;
  border: none;
  resize:none
}
textarea::-webkit-scrollbar{
  width: 0;
}
/* CSS */
textarea:focus {
  outline: none;
}

/* Styling for the copy button */
.copy-button {
width: 100%;
  background-color: #727272;
  color: #fff;
  width: min(70vw ,100% );
  border: none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 5px 10px;
  font-family:Fira Sans ;
  cursor: pointer;
  text-align: end;
}
.copy-success {
  background-color: #414141;
  color: #fff;
  width: min(70vw ,100% );
  border: none;
  font-family:Fira Sans ;
 border-top-right-radius: 4px;
 border-top-left-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  text-align: end;
}