@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');


.resumeVideo-card{
  display: flex;
  padding: 0px 0px 0px 20px;
}
.resumeVideo-detail{
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  align-items: flex-start;
  gap: 2rem;
}
.card2{
  width: 300px;
  border-radius: 7.525px;
  background: rgba(255, 253, 253, 0.73);
  box-shadow: 0px 2.50844px 20.557px 0px rgba(0, 0, 0, 0.10);
  padding: 1rem;
  height: 54vh;
}
.card2 img{
  width: 100%;
  height: 21vh;
  object-fit: cover;
}
.card1{
  width: 300px;
    border-radius: 7.525px;
    background: rgba(255, 253, 253, 0.73);
    box-shadow: 0px 2.50844px 20.557px 0px rgba(0, 0, 0, 0.10);
    padding: 1rem;
}
.card1 img{
    width: 100%;
    height: 30vh;
    object-fit: cover;
}
.resumeVideo-detail h1{
  color: #FFF;
font-family: Poppins;
font-size: 1.5rem;
font-style: normal;
font-weight: 500;
letter-spacing: 0.02525rem;
}
.resumeVideo-detail p{
  color: #FFF;
font-family: Poppins;
font-size: 1.25rem;
font-style: normal;
font-weight: 500;
line-height: 171.5%; /* 2.14375rem */
letter-spacing: 0.01875rem;
}
.resumeVideo-button{
display: inline-flex;
padding: 0.5rem 3.5625rem 0.5rem 2.5rem;
align-items: center;
cursor: pointer;
background: var(--sec, #622E27);
color: var(--tir, #F1EBCF);
font-family: Poppins;
font-size: 1.25rem;
font-style: normal;
font-weight: 500;
letter-spacing: 0.01875rem;
} 
.My-course-btm{
    width: 100%;
    height: auto;
   
  
  }
  .filterd {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: auto;
    gap: 2rem;
    justify-content: center;
}
.ViewAll-profile-btn{
    display: flex;
    width: 85vw;
    padding-top: 2rem;
    padding-bottom: 2rem;
    justify-content: end;
}
.profile-btn-viewall{
    color: #FFF;
font-family: Poppins;
border: none;
width: 8rem;
font-size: 16px;
font-style: normal;
background: var(--s, #622E27);
font-weight: 400;
line-height: 209%; 
}
.btn-begginer {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 0.8rem;
    width: 7rem;
    border-radius: 1.7px;
    border: none;
    background: #FBC9B1;
    font-style: normal;
    font-weight: 400;
    line-height: 171.5%;
    letter-spacing: 0.117px;
}
  .My-course-bto{
      display: flex;
     
      height: 15vh;
      padding: 10px 18px 10px 18px;
      align-items: center;
      justify-content: space-between;
  }
  .My-course-Add p{
    font-family:'Poppins' ;
    font-style: normal;
    font-weight: 600;
    font-size: 20.1499px;
    line-height: 24px;
    width: 159.9px;
padding: 4.2px;
left: 891px;
top: 38px;
text-align: center;
background: #F1BA53;
border-radius: 5.03749px;
    color: #FFFFFF;
  }
  .My-course-bto-11{
      display: flex;
      flex-direction: row;
      gap: 40px;
      align-items: center;
      font-family: satochi;
      font-size: 32px;
  }
  .My-course-bto-h1{
    color: #000;
font-family: Poppins;
font-size: 1.625rem;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .My-course-bto-p{
    color: var(--sec, #622E27);
    font-family: Inter;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    width: 52.86px;
    padding: 3.3px;
    background: #FFFFFF;
    border-radius: 50%;
  }


  .My-course-bui{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
  .My-course-sheats{
    padding: 20px;
  }
  .My-course-sheat{
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 400px;
    border-radius:16px ;
    background: #FFFFFF;
    
  }
  .My-course-tpsheat img{
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom: 2px solid #F2FBFA;
    filter: drop-shadow(0px 0px 13px rgba(0, 0, 0, 0.25));
    } 
    .My-course-btsheat{
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin-left: 10px;
    }
    .My-course-bat{
      display: flex;
      flex-direction:column;
      gap:10px;
      margin-top: 16px;
      align-items: center;
      justify-content: center;
     
     }
     .My-course-bit{
      font-family:Poppins ;
     color: #000;
      font-weight: 500;
      font-size: 24px;
    }
    .My-course-line-1{
      width: 96%;
    border: 1px solid #000000;
    
    }
    .My-course-coin{
      font-weight: 300;
      font-size: 16px;
      line-height: 32px;
      color: #000000;
      font-family:Poppins ;
    }
    .My-course-coin-1{
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
    
    }
    @media screen and (max-width:1030px){
      .view-itemRight{
        width: 100%;
      }
      .view-itemRight{
        width: 30%;
      }
      .card2 {
        height: 25rem;
      }
      .card2 img{
        height: 200px;
      }
      .view-itemLeft{
        width: 70%;
      }
      .card1 img{
        height: 200px;
      }
     
    }
    @media screen and (max-width: 930px ) {
      .view-itemRight{
        width: 30%;
        height: 506px;
      }
      .view-itemLeft{
        width: 70%;
        height: auto;
      }
    }
    @media screen and (max-width: 550px)and (min-width: 535px){
      .ttp {
        height: 63vh;
    }
    }
    @media screen and (max-width:880px){

       .card1 img {
        width: 53vw;
    }
  
  .view-item {
    padding: 0;
  }
  .card1 img {
    width: 100%;
    height: 18vh;
    object-fit: cover;
}
.card1 {
  height: 46vh;
  align-self: center;
  justify-content: center;
}
  .view-item1 {
    flex-wrap: wrap;
    align-items: center;
    display: flex;
    justify-content: center;
   }
   .view-itemLeft {
    width: 92%;
    height: auto;
    margin-top: 2rem;
}
.resumeVideo-detail h1{
  font-size: 1.5rem;
}
.view-itemRight {
  width: 69%;
  height: 52vh;
}
.bock{
  height: 18vh;
}
.resumeVideo-detail{
  gap: 3rem;
}
    }
    @media screen and (max-width:540px) {
      .My-course-bit{
        font-size: 20px;
      }
      .My-course-btsheat{
        gap:0.2rem
      }
      .resumeVideo-card{
        flex-direction: column-reverse;
          gap: 2rem;
          align-items: center;
         padding:0
      }
      .edit-1{
        flex-direction: column;
        gap: 2rem;
      }
      .edit-1-left{
        align-items: center;
      }
   
      
      .resumeVideo-detail{
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      .card1 {
        height: 50vh;
        align-self: center;
        justify-content: center;
    }
    .view-itemRight{
      height: 50dvh;
    }
    }
    @media screen and (max-width:420px) {
      .My-course-bto {
       flex-direction: column;
        gap: 2rem;
        height: auto;
      }
   
      .resumeVideo-card{
        margin-top: 2rem;
        padding:0;
        flex-direction: column-reverse;
      }
      .edit-1{
        flex-direction: column;
        gap: 2rem;
      }
      .card1{
        width: 81vw;
      }
      .card1 img {
        width: 73.5vw;
    }
    .resumeVideo-detail{
      padding-inline-start: 0;
    }
    .filterd{
      padding-inline: 2rem;
    }
    .ttp {
      height: 54vh;
      width: 100%;
  }
    }

    @media screen and (max-width: 380px) {
      .ttp {
        height: 69vh;
        width: 100%;
    }
    }
 