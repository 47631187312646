.Player{
    background-color: aquamarine;
    width: 150px;
    height: 450px;

}
@media screen and (max-width:1280px) {
    .Player{
       
            background-color: aquamarine;
            width: 92px;
            height: 480px;
      
    }
}
@media screen and (max-width:1028px) {
    .Player{
       
            background-color: aquamarine;
            width: 60px;
            height: 480px;
      
    }
}
