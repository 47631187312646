@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

.PrivacyPolicy-middle{
    padding-inline:4rem;
    height: auto;
    margin-top: 21px;
}
.PrivacyPolicy-main h1{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.PrivacyPolicy-main p{
    font-size: 1.1rem;
    font-family: 'Poppins';
    line-height: 1.6;
    overflow-wrap: break-word;
    word-break: break-word;
    margin-bottom: 1rem;
}
.PrivacyPolicy-top {
    /* padding-block: 1rem; */
    padding: 1rem;
}
.PrivacyPolicy-main h2{
   font-size: 2.75rem;
   font-family: 'Poppins';
   line-height: 1.1;
   font-weight: 600;
   margin-top: 1.5rem;
   margin-bottom: 1.5rem;
}
.PrivacyPolicy-main h3{
   font-size: 2.125rem;
   font-family: 'Poppins';
   line-height: 1.1;
   font-weight: 600;
   margin-top: 1.5rem;
   margin-bottom: 1.5rem;
}
.PrivacyPolicy-main li{
    font-size: 1.1rem;
    position: relative;
    margin-left: 2rem;
    margin-bottom: .5rem;
    line-height: 1.6;
    text-align: start;
    
}
.PrivacyPolicy-main strong{
    font-family: "Poppins";
    font-size: 1.1rem;
    font-weight: 600;
}
.PrivacyPolicy-container{
    display: flex;
    flex-direction: column;
    position: relative;
}
@media screen and (max-width:540px){
    .PrivacyPolicy-middle{
        padding-inline:10px;
        
    }
    
    .PrivacyPolicy-main{
        padding-inline: 10px;
    }
    .PrivacyPolicy-main li{
       
        margin-left: 1rem;
      
    }
}



.side-heading-03 {
    font-size: 24px;

}