@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

.search-container{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 21vh;
}
.search {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-right: 20px;
  }
  
  .search__input {
    color: #FFF;
    font-family: Song Myung;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.015rem;
    border: 1px solid #FFF;
    background-color: transparent;
    width:35vw;
    height: 8.5vh;
    transition: all ease-in-out .5s;
    margin-right: -4rem;
  }
  .search__input::placeholder{
color: #FFF;
font-family: Song Myung;
font-size: 1.5rem;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.015rem;
padding: 0.7rem 1rem;
  }
 
  .search__input::-webkit-input-placeholder {
    font-weight: 100;
    color: #ccc;
  }
  
  .search__button{
    border: none;
    background-color: transparent;
  }
  
  .search__button:hover {
    cursor: pointer;
  }
  
  .search__icon {
    height: 4vh;
}

.card-container{
  width: auto;
  padding: 2rem;
  /* height: 55vh; */
  height: auto;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content:center;
  gap: 2rem;
  
}
.card-container::-webkit-scrollbar{
  width: 0;
}
.Artical-contain{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  /* height: 100vh; */
  background-color: #622E27;
}
.Artical-card{
width: 28vw;
height: 50vh;
background-color: #ffffff;
border-radius: 8px;
}
.Artical-card img{
  width: 100%;
  height: 30vh;
  object-fit: cover;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.card-post{
  color: #FFF;
  font-family: Roboto;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.5rem; 
}
.card-nick{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 1rem;
  gap: 0.62rem;
  height: auto;
}
.card-nick p{
 color:#3F4047;
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 500;
padding: 0.25rem 0.5rem;
border-radius: 0.25rem;
letter-spacing: 0.00225rem;
text-transform: uppercase;
background:  #EDEFF5;
}
.card-nick span{
  color: #000;
  font-family: Roboto;
  font-size: 1rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  font-style: normal;
  font-weight: 400;
  width: 25vw;
}

.current-page {
  margin-bottom: 1rem;
  font-weight: bold;
}

.page-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 7.5vh;
  margin-bottom: 4em;
}
.Art-botm{
  height: min(72.5vh ,auto);
}
.card-collection{
  display: flex;
  height: auto;
  padding-inline: 0.5rem;
  justify-content: space-between;
  align-items: center;
}
.page-button {
  color: #FFF;
  font-family: Song Myung;
  font-size: 1.34rem;
  width: 2rem;
  background-color: #622E27;
  font-style: normal;
  border: none;
  font-weight: 400;
  letter-spacing: 0.03544rem;
  scale: 1;
}

.page-button.active {
  color: orange;
  scale: 1.3;
}
.single-code{
  width: 3.4375rem;
  height: 0.0625rem;
  border: 1px solid white;
  position: absolute;
  top: 16.5vh;
  left: 0;
}
.second-code{
  width: 9.875rem;
  height: 0.0625rem;
  border: 1px solid #C64709;
  position: absolute;
  top: 18.5vh;
  left: 0;
}

.third-code{
  width: 7.9375rem;
  height: 0.0625rem;;
  border: 1px solid white;
  position: absolute;
  top: 20.5vh;
  left: 0;
}
@media screen and (max-width:1024px) {
  .search-container{
    align-items: center;
  }
  .card-post{
    font-size: 1.5rem;
  }
  .Artical-card{
    height: 48vh;
  }
  .Artical-card img{
    height: 27vh;
  }
}
@media screen and (max-width: 998px){
  .Artical-card{
    width: 42vw;
  }
}
@media screen and (max-width: 768px){
  .search__input{
    width: 62vw;
  }
  .Artical-card{
    width: 42vw;
    height: auto;
  }
}
@media screen and (max-width: 600px) {
  .Artical-card{
    width: 70vw;
    height: 45vh;
  }
  .card-container{
    justify-content: center;
  }
  .card-nick span{
    width: 40vw;
  }
  .single-code{
    display: none;
  }
  .second-code{
    display: none;
  }
  .third-code{
    display: none;
  }
}