
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;700;800;900&display=swap');

.home-bg-image{
  width: 41vw;
  height: 98vh;
  position: relative;
  z-index:1;
}
.radid{
  color: #C64709;
 font-family: 'Red Hat Display', sans-serif;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 45.3px;
}
.plans-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: auto;
  margin-top: -14vh;
  height: calc(110vh - 80px);
  background-color: #fffced;
}
.home-title{
  color: rgba(0, 0, 0, 0.90);
  width: 45vw;
  height: auto;
 font-family: 'Red Hat Display', sans-serif;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 45.3px;

}
.home-title-text{
  color: #000000;
  width: 52vw;
  height: auto;
  font-family: Poppins;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 182%;
  letter-spacing: 0.24px;
}
.home-button{
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  border: none;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 171.5%;
  letter-spacing: 0.213px;
  border-radius: 47.847px;
  background: linear-gradient(0deg, rgba(241, 47, 47, 0.20) 0%, rgba(241, 47, 47, 0.20) 100%), linear-gradient(94deg, #C64709 0%, rgba(198, 71, 9, 0.59) 100%);
  width: 16rem;
  height: 4rem;

}
.Arrow-for-home{
  width: 3vw;
  padding-left: 2vh;

}
.home-content{
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-top: 4rem;
  padding-left: 8vh;
  gap: 3vh;
  justify-content: center;

  
}
.btn {
  width: auto;
  height: auto;
  padding-top: 5vh;
}

@media screen and (max-width:1024px) {
  .home-title{
    font-size: 2rem;
  }
  .radid{
    font-size: 2rem;
  }
  .home-button{
    width: 14rem;
    height: 3.3rem;
  }
}
@media screen and (max-width: 960px) {
  .plans-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    margin-top: -15vh;
    height: auto;
    background-color: var(--backgroudcolor);
}
.home-content {
  height: auto;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1vh;
  padding-top: 14vh;
  gap: 3vh;
  justify-content: center;
}
.home-bg-image {
 display: none;
}
.home-title {
  color: rgba(0, 0, 0, 0.90);
  width: 80vw;
  height: auto;
 font-family: 'Red Hat Display', sans-serif;
  font-size: 2.5rem;
  text-align: center;
  font-weight: 400;
  line-height: 45.3px;
}
.radid {
  font-size: 2.5rem;
}
.home-button {
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  border: none;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 171.5%;
  letter-spacing: 0.213px;
  border-radius: 47.847px;
  background: linear-gradient(0deg, rgba(241, 47, 47, 0.20) 0%, rgba(241, 47, 47, 0.20) 100%), linear-gradient(94deg, #C64709 0%, rgba(198, 71, 9, 0.59) 100%);
  width: 15rem;
  height: 4rem;
}
.home-title-text {
  color: #000000;
  width: 80vw;
  height: auto;
  font-family: Poppins;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 182%;
  letter-spacing: 0.24px;
}
.Arrow-for-home {
  width: 4vw;
  padding-left: 1vh;
}
}

@media screen and (max-width: 540px){
  .radid {
    font-size: 2rem;
  }
  .home-title{
    font-size: 2rem;
  }
  .home-title-text{
    font-size: 1.2rem;
  }
  .home-button{
    width: 15rem;
    height: 3rem;
  }
}

@media screen and (max-width: 420px){
.Arrow-for-home {
    width: 8vw;
    padding-left: 0vh;
}
.home-title-text {
  font-size: 1rem;
}
.radid {
  font-size: 1.75rem;
}
.home-title{
  font-size: 1.75rem;
}
.home-title-text{
  width: 90%;
}
.home-title{
  width: 95%;
}
.home-button {
  width: 14rem;
  height: 2.5rem;
  font-size: 1.2rem;
}
}
h1 {
  font-family: Red Hat Display;
}