
@import url('https://fonts.googleapis.com/css2?family=Song+Myung&display=swap');

.About-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}
.About-top-contain{
    display: flex;
    align-items: center;
    height: auto;
    padding-inline:8rem;
    justify-content: space-between;
    background: linear-gradient(92deg, #622E27 -1.42%, #C24609 100.39%);
}
.About-top-contain img{
    width:40vw;
    height: 25vh;
    object-fit:contain;
    mix-blend-mode: luminosity;
}
.About-left{
    display: flex;
    flex-direction: column;
    gap:1.5rem;
    align-items: flex-start;
}
.About-left h1{
color: #FFF;
font-family: Song Myung;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
}
.About-left p{
color: var(--tir, #FFFCED);
font-family: Song Myung;
font-size: 21px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
}
.About-body{
   
        width: 100%;
        height: auto;
        background: var(--tir, #FFFCED);
        display: flex;
        justify-content: center;
   
}
.About-body-contain{
   
    display: flex;
    padding-block: 4rem;
    width: calc(100% - 16rem);
    flex-direction: column;
}
.About-body-contain-1 p{
    color: rgba(0, 0, 0, 0.90);
    font-family: Song Myung;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}
.About-body-details{
    display: flex;
    flex-direction: column;
    gap:2rem;
    padding-block-start:4rem;
}
.About-body-details h1{
color: var(--pri, #C64709);
font-family: Song Myung;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.9px;
margin: 0;
}
.About-body-details p{
color: rgba(0, 0, 0, 0.92);
font-family: Song Myung;
font-size: 21px;
font-style: normal;
font-weight: 400;
letter-spacing: 0.8px;
margin: 0;
}
.About-developer-contain{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.developer-details{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap:2rem;
    justify-content: space-between;
    
}
.developer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  justify-content: center;
}

.developer-details img{
    width: 22.5vw;
    
}
.developer-item:hover{
  background-image:url('../Image/Frame\ 5614\ \(1\).png');
  background-repeat: no-repeat;
  background-size: contain;
}
.developer-dropdown{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    gap: 2rem;
}
.aboutus-they-logo{
    width: 35px;
    cursor: pointer;
    height: 35px;
}
.theyright-aboutus{
    width: 50px;
    height: 50px;
}
.developer-dropdown img{
    width: 40.722px;
    height: 20.701px;
    text-align: center;
}
.user-name {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  position: relative;
  z-index: 1;
  margin-left: -28px;
  align-items: flex-start;
}
.user-name h1{
  color: #5f2929;
font-family: Song Myung;
font-size: 29.85px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.user-name p{
  color: #FFF;
font-family: Song Myung;
font-size: 18.242px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
    .stone{
      background-image: url(https://h2o.ai/etc.clientlibs/h2o/clientlibs/clientlib-site/resources/images/kaggle-grey-blob.png);
      background-repeat: no-repeat;
      background-blend-mode: color-burn;
      position: absolute;
      background-size: contain;
      height: 100px;
      width: 100px;
      margin-left: -28px;
      z-index: 1;
    }
   

/* animation     */

.developer-container {
  
  height: 0;
  background-color: rgb(115, 220, 255);
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.8s ease-out;
}

.developer-container.open {
  animation: expandHeight 0.8s ease-out forwards;
}

.developer-container.close{
  animation: CloseHeight 0.8s linear forwards;
}
@keyframes expandHeight {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 80vh;
    opacity: 1;
  }
}
@keyframes CloseHeight {
  from {
    height: 80vh;
    opacity: 1;
  }
  to {
    height: 0;
    opacity: 0;
  }
}

.developer-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rio {
  display: flex;
}
.spring{
  width: 280px;
  height: 280px;
  object-fit: cover;
  border-radius: 50%;
}
.user-cards {
  display: flex;
  gap: 4rem;
}

.hello-world {
  text-align: center;
  margin-top: 20px; /* Adjust as needed */
}

@keyframes slideFromTop {
  0% {
    transform: translateY(-100%);
  }
 
  100%{
    transform: translateY(0);
  }
}

  .About-btm-contain{
     display: flex;
     height: auto;
     background: var(--tir, #FFFCED);
     padding-inline: 8rem;
     padding-block-end: 8rem;
  }
  .About-btm-details{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap:2rem;
    padding-inline: 4rem;
    padding-block: 2rem;
    border-radius: 16px;
background: linear-gradient(97deg, #622E27 0.87%, #C24609 97.79%);
    
  
  }
  .About-btm-details h1{
color: var(--tir, #FFFCED);
font-family: Song Myung;
font-size: 36px;
font-style: normal;
font-weight: 400;
margin: 0;
  }
  .About-btm-details p{
 color: #FFF;
font-family:Song Myung;
font-size: 20px;
font-style: normal;
font-weight: 500;
width: 672px;
margin: 0;
  }



  .about-containe{
    width: calc( 100% - 16rem);
    background-color: aquamarine;
    height: 80vh;
}
.about-container-top-1{
    width: 100%;
    height: 40vh;
    background: var(--c1, linear-gradient(132deg, #C24609 18.86%, #EE9277 67.45%, #CD5924 99.88%));
}
.about-they{
display: flex;
align-items: center;
justify-content: space-between;
height: 10vh;

padding-inline: 4rem;
}
.they-details{
display: flex;
align-items: center;
justify-content: space-between;
height: 30vh;
padding-inline: 4rem;
}
.they-details-right {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.they-details-right p {
    width: 350px;
}
.about-container-top2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45vh;
    background: #DAD9D3;
}
.about-container-top2-left{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 4rem;
}
.about-container-top2-left img {
  width: 34vh;
  position: relative;
  height: 34vh;
  top: -14rem;
  left: -13rem;
  border-radius: 50%;
  object-fit: cover;
}
.they-right-1 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.they-right-1 p{
    color: #000;
   font-family: Song Myung;
    font-size: 18.242px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.about-container-top2-left-1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    width: 400px;
}
.about-container-top2-left-1 img{
    width: 40.237px;
    height: 40.237px;
    position: relative;
    top:0 ;
    left: 0;
}
.about-container-top2-left-1 p{
    color: #000;
   font-family: Song Myung;
    font-size: 18.242px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.social-icons img{
    width: 27.362px;
    height: 27.362px;
    position: relative;
    top:0 ;
    left: 0;
}
.social-icons h1{
    color: #000;
   font-family: Song Myung;
    font-size: 32.242px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.social-icons span{
    display: flex;
    align-items: center;
    justify-content: center;gap: 1rem;
}
.about-container{
    display: flex;
    flex-direction: column;
    height: 85vh;
    
}
@media screen and (max-width:1024px){
    .about-container-top2-left img{
        top: -8rem;
        left: -5rem;
    }

    .spring{
      width: 275px;
      height: 275px;
    }
    .they-details-right p{
      width: 300px;
    }
    .about-container-top2-left-1 img{
        top: 0;
        left: 0;
    }
    .social-icons img{
        top: 0;
        left: 0;
    }
    .they-right-1{
        width:min(300px,100%);
    }
}

@media screen and (max-width:822px) {
    .about-container-top2-left img{
        display: none;
    }
    .spring{
      width: 250px;
      height: 250px;
    }

    .About-btm-details{
      padding-inline: 4rem;
    }
    .About-btm-details p{
      width: auto;
    }
    .social-icons img{
    display: block ;
    }
    .about-container-top2-left{
        gap: 2rem;
        padding-inline: 2rem;
        width: 100%;
    }
    .about-container-top2-left-1 p{
        font-size: 26.242px;
    }
    .they-right-1 p{
        font-size: 26.242px;
    }
    .they-details{
      padding-inline: 2rem;
    }
    .developer-details img {
      width: 39.5vw;
  }
  .about-container-top2-left-1{
    width: 400px;
  }
  .developer-dropdown img {
    width: 40.722px;
    height: 20.701px;
    text-align: center;
}
.About-body-contain {
 
  width: calc(100% - 8rem);
  
}
}
@media screen and (max-width:540px){
    .about-container{
     height: auto;
    }
    .about-container-top-1{
        height: auto;
    }
    .spring {
      width: 250px;
      height: 250px;
  }
    .they-details-right{
        flex-direction: column;
        gap:4rem;
        padding:1rem
    }
    .they-details{
        height: auto;
    }
    .about-container-top2-left{
        flex-direction: column;
    }
    .About-btm-contain{
      padding-inline: 4rem;
      padding-block-end: 4rem;
    }
    .About-body-contain{
      width: calc(100% - 4rem);
    }
    .about-container-top2-left-1 p{
      font-size: 22.242px;
    }
    .they-right-1 p {
      font-size: 22.242px;
  }
  .developer-details img {
    width: 39vw;
}
.developer-dropdown img {
  width: 40.722px;
  height: 20.701px;
  text-align: center;
}
.About-top-contain{
  padding-inline: 2em;
}
}
@media screen and (max-width:439px){
    .they-details-right p {
      width: 280px;
        font-size: 18.242px;
    }
    .spring {
      width: 350px;
      height: 350px;
  }
    .about-container-top2-left-1{
        width: 300px;
    }
    .about-container-top2-left-1 p {
        font-size: 18.242px;
    }
    .social-icons h1{
        font-size: 26.242px;
    }
    .developer-details img {
      width: 88vw;
  }
  .user-cards {
    display: flex;
    gap: 4rem;
    flex-direction: column;
}
.about-container-top2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32vh;
  background: #DAD9D3;
}
  .developer-dropdown img {
    width: 40.722px;
    height: 20.701px;
    text-align: center;
  }
  .About-btm-contain {
    padding-inline: 2rem;
    padding-block-end: 2rem;
}
.user-stone {
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-name{
  align-items: center;
}
.user-name p{
  width: 210px;
}
.About-top-contain{
  padding:0
}
}

@media screen and (max-width:1025px) {
  
img.management-img {
    width: 34vh;
    position: relative;
    height: 34vh;
    top: -11rem;
    left: -13rem;
    border-radius: 50%;
    object-fit: cover;
}
}