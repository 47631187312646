@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

body{
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
}
.termcondition-container{
    display: flex;
    flex-direction: column;
    position: relative;
}


.termcondition-middle{
    padding-inline:4rem;
    width: 100%;
    height: auto;
}
.termcondition-main h1{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.termcondition-main{
   
}
.termcondition-main p{
     font-size: 1.1rem;
     font-family: 'Poppins';
     line-height: 1.6;
     overflow-wrap: break-word;
     word-break: break-word;
     margin-bottom: 1rem;
}
.termcondition-main h2{
    font-size: 2.75rem;
    font-family: 'Poppins';
    line-height: 1.1;
    font-weight: 600;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.termcondition-main h3{
    font-size: 2.125rem;
    font-family: 'Poppins';
    line-height: 1.1;
    font-weight: 600;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
h5 {
    font-size: 25px;
}
@media screen and (max-width:540px){
    .termcondition-middle{
        padding-inline:10px;
        
    }
    .termcondition-main{
        padding-inline: 10px;
    }
    .termcondition-main li{
       
     margin-left: 1rem;
 }
}