@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&family=Poppins:ital,wght@0,200;0,400;1,200;1,300&family=Song+Myung&family=Sora:wght@300;400&display=swap');


.pay-details-popup {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.plan-price-tag {
    color: #4E4E5A;

    font-family: 'Poppins', sans-serif;

font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.24px;
}
.popup-reward-span {
    color: #4E4E5A;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.18px;
}
.reward-h3 {
    color: #C64709;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.24px;
}
.popup-bottom-details button {
    border-radius: 8px;
    padding: 14px 50px 14px 50px;
border: 1px solid #FFF;
background: #C64709;
color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.14px;
}
.reward-check-box {
    color: #4E4E5A;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.18px;
}
.pay-popup-container {
    border-radius: 2px;
    border: 1px solid #FFF;
    background: #FFF;
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.25);
    width: 606px;
    height: 346px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}
.popup-bottom-details {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}