/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

table {
 
    border-collapse: collapse;
    padding: 0;
    width: 100%;
    background: #DDE0F6;
    table-layout: fixed;
    border-bottom: 0.858108px solid #000000;
  }
  
  table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
  }
  /* div#simple-tabpanel-1 {
    padding: 4rem;
} */

.table-row {
  border: 1px solid #000 ;
  width: 100%;
}
  .certificate-Container{
    display: flex;
    align-items: center;
    height: 50vh;
    justify-content: center;
  }
  table td {
    text-align: start;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    padding: 18px;
    font-size: 18px;
    border: 1px solid black;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.9);
}
.recent-purchase-main {
  display: flex;
  align-items: center;
  margin-top: 5vh;
  justify-content: center;
}
  
  table th {
padding: 8px 8px 8px 30px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
border: 2px solid black;
text-align: start;
font-size: 18px;

line-height: 33px;
background: #DDE0F6;
color: #1A124E;
  }
  
  .view-certificate{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    position: relative;
   
    font-size: 16.2698px;
    line-height: 20px;
    width: 162.7px;
    padding: 4px;
    text-align: center;
    background: #F1BA53;
    border-radius: 6.10118px;
    color: #FFFCFC;
  }
  .courseview{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  th.purchase-table-S\.No {
    width: 20px;
    text-align: center;
    font-size: 24px;
    margin: 0;
    background-color: white;
    display: flex;
    align-self: flex-start;
}
  @media screen and (max-width: 600px) {
    table {
      border: 0;
    }
  
    table caption {
      font-size: 1.3em;
    }
    .courseview{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }
    
    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
    }
    
    table td::before {
    
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    
    table td:last-child {
      border-bottom: 0;
    }
    .view-certificate{
        
        position: absolute;
        right: 12px;
      
      }
  }
/* @media screen and (max-width:420px){
  div#simple-tabpanel-1 {
    padding: 1rem;
  }
} */