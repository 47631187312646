.Jargons-container {
    background-color: var(--backgroudcolor);
    height: auto;
  
}

.Jargons-profileimg {
    width: 3rem;
}
.NoData {
    position: absolute;
    top: 50%;
    left: 40%;
    font-family: 'Poppins';
    font-size: 32px;
}
.Jargons-profile {
    display: flex;
    padding-right: 1vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    justify-content: end;
    align-items: center;
    border-bottom: 1.138px solid #FFF;
}
.total-jargons {
    color: #000;
    font-family: Poppins;
    font-size: 24.05px;
    text-align: end;
    padding-right: 2vw;
    font-style: normal;
    padding-top: 1vw;
    font-weight: 400;
    line-height: 171.5%;
    letter-spacing: 0.361px;
}
.Cluster-text {
    color: var(--pri, #C64709);
    font-family: Song Myung;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 171.5%;
    letter-spacing: 0.722px;
}
.back-jargons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 1em;
}
.Cluster-c {
    padding: 1vw;
}
.abcd-C {
    display: flex;
    background: var(--sec, #622E27);
    align-items: center;
    height: 5rem;
    justify-content: center;
}
.abcd-text {
    color: #FFF;
    font-family: Song Myung;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 171.5%;
    letter-spacing: 0.601px;
}
.search-icon {
    width: 14vw;
    
}
.s-simpal {
    color: var(--sec, #622E27);
    font-family: Poppins;
    font-size: 128.268px;
    font-style: normal;
    font-weight: 400;
    line-height: 171.5%;
    letter-spacing: 1.924px;
}

.SATOSHI {
    color: var(--sec, #622E27);
    font-family: Song Myung;
    font-size: 1.8rem;
    font-style: normal;
    width: 36vw;
    font-weight: 400;
    line-height: 171.5%;
    letter-spacing: 0.481px;
}
.Satoshi {
    color: #000;
    font-family: Poppins;
    font-size: 1.2rem;
    font-style: normal;
    text-align: justify;
    width: 36vw;
    font-weight: 400;
    line-height: 171.5%;
    letter-spacing: 0.361px;
}
.Satoshi-con-text {
    display: flex;
    width: 80vw;
    padding-bottom: 2rem;
    flex-wrap: wrap;
    justify-content: space-between;
}
.Satoshi-cont {
    display: flex;
    gap: 6vw;
    flex-direction: column;
}
.Satoshi-C {
    display: flex;
    flex-direction: row;
}
.Satoshi-con {
    padding-top: 1rem;
}
.s-simpal-C {
     padding-left: 2rem;
}
.abcd-text span {
    cursor: pointer;
    padding: 10px;
    color: #FFF; 
  }
  .more-btn{
  background-color: #C64709;
  padding:  10px , 5px;
  border: none;
  width: 100px;
  }
  .less-more{
    color: #C64709;
    border: none;
    font-size: 1rem;
  }
  .more-btn-container{
    height: 100px;
  }
  
  .abcd-text span.clicked {
    color: #C64709;
  }
  
  @media screen and (max-width:820px) {
    .Satoshi-C{
        flex-direction: column;
        padding: 2em;
    }
    .Satoshi{
        width: auto;
    }

  }
  @media screen and (max-width:540px){
    .abcd-text{
        display: flex;
        flex-direction: column;
    }
    .abcd-C{
        overflow-y: scroll;
        align-items: start;
        height:200px;
    }
    .back-jargons{
        padding: 0;
    }
  }