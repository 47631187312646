body{
    padding: 0;
    margin: 0;
}
.Trinity-container{
   width: 100%;
   height: auto;
   background-color: #F1EBCF;
   display: flex;
   flex-direction: column;
   
}

.trinity-nav{
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   height: 10vh;
   padding-right: 2rem;
   background-color: #F1EBCF;
}
.trinity-nav img{
  
   height: 3.0625rem;
 
}
.trinity-body{
   width: 100%;
   height:auto;
   display: flex;
   flex-direction: column;
}
.trinity-top-container{
   width: 100%;
   height: 20vh;
   display: flex;
   align-items: center;
   justify-content: space-around;
   background-color: #622E27;
}
.trinity-top-container h1{
color: #FFF;
font-family: Song Myung;
font-size:clamp(1.5rem , 3vw , 3rem);
font-style: normal;
font-weight: 400;
letter-spacing: 0.045rem;
}
.world-1{
  
   width: 5.44113rem;
   height: 5.97444rem;
   position: relative;
   top: -18px;
}
.doller-1{
width: 2.69519rem;
height: 2.87819rem;
position: relative;
top:18px
}

.body-container-1{
     width: auto;
     background-image: url('../../../Images/Vector\ 47.png');
     background-size: cover; 
     background-position: inherit; 
     background-repeat: no-repeat;
     display: flex;
     flex-direction: column; 
}
.body-container-1::-webkit-scrollbar{
   width: 0;
}
.contein{
   width: auto;
   padding: 3rem;
}
/* .contein-img{
   width: 100%;
   display: flex;
   align-items:center;
   justify-content: flex-end;
} */
.contein-pora img {
    width: 1.6vw;
}
.contein-pora p{
color: var(--sec, #622E27);
font-family: Poppins;
font-size: 1.125rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.contein-1{
  
   height: 60vh;
   background-color: transparent;
   padding-inline: 2rem;
   border: 1px solid #C64709;
   display: flex;
   flex-direction: column;

}
.contein-1:hover{
   background-color: #F1EBCF;
}
.contein-pora{
   display: flex;
   flex-direction: row;
   gap:1rem;
   align-items: center;
   justify-content: flex-end;
}
.contein-pora p{
   color: var(--sec, #622E27);
   font-family: Poppins;
   font-size: 1.125rem;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
}
.contein-1 h1{
color:  #C64709;
font-family: Song Myung;
font-size:clamp( 1.225rem , 2vw + 1rem ,  2.625rem);
font-style: normal;
font-weight: 400;
letter-spacing: -0.06563rem;
}
.contein-left{
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 50vh;
   gap:1rem;
   align-items: flex-start;
   justify-content: space-around;
  
}
.contein-left h1{
   font-family: Song Myung;
   font-size:clamp( 1.225rem , 2vw + 1rem ,  2.625rem);
   font-style: normal;
   font-weight: 400;
   letter-spacing: -0.06563rem;
   padding-inline-start: 3rem;
   height: 10vh;
}
.contein-left img{
   height: 45vh;
   max-width: 100%;
   padding-inline-start: 1.5rem;
   padding-block-end: 1.5rem;
}
.contein-right{
   width: 100%;
   height: 50vh;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   gap: 1.25rem;
   
}
.contein-right-des {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 1.25rem;
}
.contein-right-des1{
   display: flex;
   align-items: center;
   gap: 1.5rem;
}
.contein-right-des1 img{
   width: 2.07319rem;
   height: 2.07319rem;
}
.contein-right-des1 p{
color: rgba(0, 0, 0, 0.87);
font-family: Poppins;
font-size: 1.25rem;
font-style: normal;
font-weight: 400;

}
.contein-right-des2{
   display: flex;
   align-items: center;
   gap: 1.5rem;
}
.contein-right-des2 img{
   width: 2.07319rem;
   height: 2.07319rem;
}
.contein-right-des2 p{
color: rgba(0, 0, 0, 0.87);
font-family: Poppins;
font-size: 1.25rem;
font-style: normal;
font-weight: 400;

}
.contein-right-des3{
   display: flex;
   align-items: center;
   gap: 1.5rem;
}
.contein-right-des3 img{
   width: 2.07319rem;
   height: 2.07319rem;
}
.contein-right-des3 p{
color: rgba(0, 0, 0, 0.87);
font-family: Poppins;
font-size: 1.25rem;
font-style: normal;
font-weight: 400;

}
.contein-right-des4{
   display: flex;
   align-items: center;
   gap: 1.5rem;
}
.contein-right-des4 img{
   width: 2.07319rem;
   height: 2.07319rem;
}
.contein-right-des4 p{
color: rgba(0, 0, 0, 0.87);
font-family: Poppins;
font-size: 1.25rem;
font-style: normal;
font-weight: 400;

}
.contein-right-des5 a{
color: #FFF;
font-family: Poppins;
font-size: 1.01588rem;
font-style: normal;
font-weight: 500;
padding: 0.47406rem 3.08556rem 0.51625rem 2.97975rem;
border-radius: 0.47406rem;
background:  #C64709;
}
.contein-right-des5{
   width: 100%;
   display: flex;
   justify-content: flex-end;
   align-items: center;
  
}
.contein-right-title{
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
}
.contein-right-title h1{
color: #622E27;
font-family: Song Myung;
font-size: clamp(1rem , 1vw + 1rem , 1.875rem);
font-style: normal;
font-weight: 400;
letter-spacing: 0.02813rem;
}
.left\&right {
   display: flex;
   width: 100%;
   height: 55vh;
   align-items: center;
   justify-content: space-between;
   gap: 4rem;
}
.back-arrow-02 {
  height: auto;
  width: 10vw;
   color: #622E27;
}



@media screen and (max-width: 450px) {
   .left\&right {
      display: flex;
      flex-direction: column;
      
   }
   .contein-1 {
      height: 106vh;
   }
   .contein-left img {
     
      max-width: 100%;
      height: auto;
      padding: 0;
     
  }
  .trinity-top-container {
   height: 13vh;
  }
}