@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
.loader {
  position: relative;
  width: 54px;
  height: 54px;
  border-radius: 10px;
}

.loader div {
  width: 8%;
  height: 24%;
  background: var(--sqas-q, linear-gradient(1deg, #CA541A 6.87%, rgba(232, 94, 50, 0.68) 57.41%, #EA8765 67.28%, rgba(163, 65, 24, 0.72) 100%));
  position: absolute;
  left: 50%;
  top: 30%;
  opacity: 0;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
  animation: fade458 1s linear infinite;
}

@keyframes fade458 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.25;
  }
}

.loader .bar1 {
  transform: rotate(0deg) translate(0, -130%);
  animation-delay: 0s;
}

.loader .bar2 {
  transform: rotate(30deg) translate(0, -130%);
  animation-delay: -1.1s;
}

.loader .bar3 {
  transform: rotate(60deg) translate(0, -130%);
  animation-delay: -1s;
}

.loader .bar4 {
  transform: rotate(90deg) translate(0, -130%);
  animation-delay: -0.9s;
}

.loader .bar5 {
  transform: rotate(120deg) translate(0, -130%);
  animation-delay: -0.8s;
}

.loader .bar6 {
  transform: rotate(150deg) translate(0, -130%);
  animation-delay: -0.7s;
}

.loader .bar7 {
  transform: rotate(180deg) translate(0, -130%);
  animation-delay: -0.6s;
}

.loader .bar8 {
  transform: rotate(210deg) translate(0, -130%);
  animation-delay: -0.5s;
}

.loader .bar9 {
  transform: rotate(240deg) translate(0, -130%);
  animation-delay: -0.4s;
}

.loader .bar10 {
  transform: rotate(270deg) translate(0, -130%);
  animation-delay: -0.3s;
}

.loader .bar11 {
  transform: rotate(300deg) translate(0, -130%);
  animation-delay: -0.2s;
}

.loader .bar12 {
  transform: rotate(330deg) translate(0, -130%);
  animation-delay: -0.1s;
}



.Address{  
      width: 100%;
      height: 100vh;
       
  }
  .ttp{
      height: 50vh;
      width: 100%;
  }
  .tpo-1 {
    width: 100%;
    height: 10vh;
    padding: 1em;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
}
.signin-loader{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:3rem;
  align-items: center;
}

.signin-title{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 34.3243px;
  line-height: 42px;
color: #622E27;
}
.no-purchage-C {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center ;
}
.bock {
    width: 100%;
    height: 25vh;
    position: absolute;
    z-index: -1;
    object-fit: cover;
    background: var(--sqas-q, linear-gradient(1deg, #CA541A 6.87%, rgba(232, 94, 50, 0.68) 57.41%, #EA8765 67.28%, rgba(163, 65, 24, 0.72) 100%));
background-blend-mode: darken;
}
    .total1{
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 40vh;
      font-family: imported;
  }
  .password{
     padding: 5px 5px 0px 5px;
     background-color: rgb(52 52 52);
     border-radius: 50%;
     margin-left: 20px;
  }
  .btt-1{
      display: flex;
      align-items: center;
      width: 100%;
      height: 35vh;
     
  }
  .baat1{
     
      display: flex;
      align-items: center ;
      flex-direction: row;
      margin-left: 48px;
     
   }
  
    .bvo img{
      width: 125px;
      height: 125px;
      object-fit: cover;
      background-color: white;
      border-radius: 50%;
   }
    .bv-1{
       display: flex;
       flex-direction: column;
       justify-content: center;
       padding-inline: 10px;
       object-fit: cover;
       gap:5px;
   }
    .bv-11{
    line-height:36px;
    margin-bottom: 40px;
   }
   .bv-0{
  display: flex;
  text-align: center;
  align-items:baseline;
  gap: 8px;
  }
  .bv-111.MuiBox-root.css-0 {
    text-align: start;
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: center;
}
   .bv-111 span{
    color: var(--tir, #F1EBCF);
    font-family: Poppins;
    font-size: 2.10419rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
   }
   .bv-111 p{
    color: var(--tir, #F1EBCF);
    font-family: Poppins;
    font-size: 1.15731rem;
    font-style: normal;
    font-weight: 300;
   }
   .bv-112{
     color: #ffffff;
     font-weight: 300;
     font-size: 22px;
   }
   .edit-1{
      display: flex;
      width: 95%;
      align-items: center;
      justify-content: space-between;
      line-height: 48px;
  }
  .edit-1-left{
      display: flex;
      flex-direction: column;
      gap:10px
  }
  .edit-1-left h1{
     
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 27.4595px;
line-height: 33px;

color: #000000;
  }
  .edit-1-left p{
    color: var(--sec, #622E27);
    font-family: Poppins;
    font-size: 1.05206rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

.editprofile-logout p{
    display: flex;
    width: 6.52294rem;
    height: 2.15675rem;
    padding: 0.30363rem 1.17231rem 0.35313rem 1.22556rem;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.90);
font-family: Poppins;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: uppercase;
border-radius: 0.31563rem;
background: var(--sec, #622E27);

}
  .editprofile-text{
    display: flex;
    width: 9.41613rem;
    height: 2.41981rem;
    padding: 0.29581rem 1.13075rem 0.24894rem 1.47288rem;
    justify-content: flex-end;
    align-items: center;
    border-radius: 0.47344rem;
border: 0px solid #FFFCFC;
background: var(--sqas-q, linear-gradient(1deg, #CA541A 6.87%, rgba(232, 94, 50, 0.68) 57.41%, #EA8765 67.28%, rgba(163, 65, 24, 0.72) 100%));
background-blend-mode: darken;
  }
  .editprofile-text p{
    color: #FFFCFC;
    font-family: Poppins;
    font-size: 1.2625rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .navi-item{
    display: flex;
    align-items: center;
    gap:30px;
    justify-content: space-around;
  
  }
.view-item{

    height: auto;
    display: flex;
    flex-direction: column;
    gap:30px;
    padding: 30px;
}
.view-item1{
    width: 100%;
    height: auto;
   display: flex;
   flex-wrap: nowrap;
   flex-direction: row;
   gap: 30px;
   align-items: center;
   justify-content: space-between;

}
.view-itemLeft{
  height: 34rem;
  width: 58%;
  padding: 1rem;
border-radius: 0.263rem;
background: #F19263;
box-shadow: 0px 2.5648739337921143px 5.1297478675842285px 0px rgba(0, 0, 0, 0.25);
}
.view-itemRight{
 width: 30%;
 height: 34rem;
 padding: 1rem;
 border-radius: 0.263rem;
 background: #F19263;
 box-shadow: 0px 2.933809280395508px 2.933809280395508px 0px rgba(0, 0, 0, 0.25);
}
.itemRight-top{
    width: 100%;
    height: 80px;
    padding: 20px 0px 0px 20px;
}
.itemRight-top p{
    color: var(--sec, #622E27);
    font-family: Poppins;
    font-size: 1.46688rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.itemRight-btm{
    width: 100%;
    height: 204.52px;
    display: flex;
    flex-direction: column;
  
    align-items: center;
    justify-content: center;
}
.itemRight-btm p{
    font-family: Inter;
    font-size: 11.18356rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: linear-gradient(140deg, #A32D14 0%, #FBF9F6 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.itemRight-btm span{
    color: #000;
    font-family: Poppins;
    font-size: 1.10019rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.upgrade-plan{
 width: 100%;
height: 105.21px;
background: #DDE0F6;
border-radius: 5px;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0px 30px 0px 30px;
}
.upgrade-plan p{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 26px;
line-height: 31px;

color: #000000;
}
.upgrade-plan span{
 font-family: 'Poppins';
font-style: normal;
width: 175px;
padding: 4.3px;
background: #F1BA53;
border-radius: 5.01109px;
font-weight: 600;
font-size: 20.0444px;
line-height: 24px;
text-align: center;
color: #FFFFFF;
}
.crashcourse-total{
height:auto;
border-radius: 0.263rem;
background: #F19263;
box-shadow: 0px 2.5648739337921143px 5.1297478675842285px 0px rgba(0, 0, 0, 0.25);

}
.card-duration {
    display: flex;
    justify-content: end;
}
.card-sec-begginer {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.resumevideo-top{
    width: 100%;
    height: 80px;
    padding: 20px 0px 0px 20px;
   
}
.resumevideo-top p{
    color: var(--sec, #622E27);
font-family: Inter;
font-size: 1.68331rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.resumevideo-btm{
    width: 100%;
    display: flex;
    height: 274.34px;
    padding: 20px;
    gap: 30px;
  
    align-items: center;
    justify-content: space-between;
}
.shoat{
    display: flex;
    flex-direction: column;
width: 162.42px;
height: 204.52px;
background-color: #ffffff;
border-bottom: 0.601544px solid rgba(0, 0, 0, 0.17);
border-radius: 15.573px;
    
  }
  .tpshoat img{
    width: 162.42px;
    height: 99.59px;
    object-fit: cover;
    border-top-right-radius: 15.573px;
    border-top-left-radius:  15.573px;
 
    } 
    .btshoat{
      display: flex;
      width: 100%;
      height: 104.93px;
      align-items: flex-start;
      justify-content:center;
      flex-direction: column;
      gap:10px;
      margin-left: 10px;
    }
    .but{
      display: flex;
      flex-direction:column;
      gap:10px;
      align-items: center;
      justify-content: center;
     
     }
     .bkt{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 9.83561px;
        line-height: 12px;
        color: #000000;
    }
    .Overview{
      color:'#000000';
      font-size:'21.9333px';
      font-weight:'400';
      font-family:'Poppins'
    }
    .lone-1{
        width: 88%;
        border: 1px solid #000000;
        display: flex;
        margin-right: 20px;
        align-items: center;
        justify-content: flex-start;
    
    }
    .coon{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 6.55707px;
        line-height: 8px;
      color: #000000;
        
    }
    .coon-1{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 8.19634px;
        line-height: 10px;
        color: #000000;
    
    }
    .btm-right{
        display: flex;
        align-items: center;
        flex-direction: column;
      
        width: 100%;
        gap:20px;
    }
    .btm-right img{
      
        display: flex;
        align-items: center;
        align-self: flex-start;
    
    }
    .editprofile-logout {
      cursor: pointer;
  }
    .btm-right p{
        width: 441px;
        height: 48px;
        font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20.2px;
line-height: 24px;

color: #000000;




    }
    .css-1fj30ug-MuiButtonBase-root-MuiTab-root.Mui-selected{
      color: #622E27 !important;
    }
    .css-1csmo0z-MuiButtonBase-root-MuiTab-root.Mui-selected{
        color: #622E27 !important;
      }
      .css-1aquho2-MuiTabs-indicator{
        background-color: #000000 !important;
      }

    @media (max-width: 1024px) {
      
      }
      
     
      @media (max-width: 820px) {
        .view-item1{
          flex-wrap: wrap;
        }
        .view-itemLeft{
          width: 100%;
          height: 45rem;
        }
        .view-itemRight{
          height: 50dvh;
          width: 100%;
        }
        .navi-item {
          flex-direction: column;
          gap: 15px;
        }
  
        .baat1 {
          flex-direction: column;
          align-items:center;
          margin-left: 0;
         
        }
      
        .btt-1 {
          flex-direction: column;
          height: 25vh;
        }
      }
      @media screen and (max-width:540px){
        .btt-1 {

          height: 59vh;
      }
    
      .resumevideo-btm{
        flex-direction: column;
      }
      .ttp {
        height: 58vh;
        width: 100%;
    }
    .bv-111 span{
      font-size: 1.2rem;
    }
    .card2 {
      width: auto;
  }
  .view-itemLeft {
    width: 92%;
    height: auto;
}
      }
      @media screen and (max-width:420px){
        .btm-right p{
          width: 289px;
         
          line-height: 28px;
        }
        .edit-1-left h1{
          font-size: 20.4595px;
        }
        .edit-1-left p{
          font-size: 15.8784px;
        }
       
      .edit-1{
        gap:1rem
      }
      .editprofile-text {
        width: 128.6px;
        padding: 8px;
      }
     
    .btm-right{
      gap:4rem
    }
      }