@import url('https://fonts.googleapis.com/css2?family=Song+Myung&display=swap');

.player{
    width: 450px;
    height: 120px;
}
.prof-text{
    color: #091345;
    font-family: Song Myung;
    font-size: 3vw;
    text-align: center;
    width: 81vw;
    font-weight: 400;
    line-height: 171.5%;
    letter-spacing: 0.6px;
}
.Proffes-container{
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    background-color: #fffced;
}
.proff-con-text{
    color: rgba(0, 0, 0, 0.52);
    text-align: center;
    font-family: Poppins;
    font-size: 18.695px;
    width: 40rem;
    font-weight: 600;
    line-height: normal;
}
.Proff-heading{
    color: #000;
text-align: center;
font-family: Poppins;
font-size: 16.887px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.Proff-head-text{
    color: rgba(0, 0, 0, 0.74);
    text-align: center;
    font-family: Poppins;
    font-size: 11.821px;
    width: 222px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.177px;
}
.Proffes-Frame-Cont{
    display: flex;
    height: 55vh;
    width: auto;
    gap:1rem;
    flex-direction: row;
}
.proff-image{
    width: 70px;
    height: 70px;
}
.Profes-First-Frames{
    display: flex;
    align-items: center;
    gap: 12px;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    .Proffes-container{
       display: none;
    } 
}
