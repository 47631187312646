/* 
.raise-ticket-select{
    color: rgba(0, 0, 0, 0.50);
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.225px;
    width: 24rem;
    border: 2px solid rgb(153, 149, 149);
    height: 3rem;
}
.Raise-tickets-container {
    display: flex;
    align-items: center;
    height: calc(100vh - 90px);

    justify-content: center;
}
.Raise-ticket-main {
    height: 88vh;

    width: 70vw;

}
.raise-submit {
    color: #1D2061;
    font-family: Poppins;
    font-size: 2rem;
    padding-bottom: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.451px;
}
.support-raise {
    color: #000;
    font-family: Poppins;
    font-size: 1.5rem;
    padding-bottom: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.27px;
}
.raise-enter-req {
    border-radius: 4px;
    width: 50vw;
    background-color: #FFF;
    font-family: Poppins;
    font-size: 1.2rem;
    height: 18vh;
    border: 2px dashed #000;
}
.raise-ask {
 
    color: #000;
    font-family: Poppins;
    font-size: 1.2rem;
    font-style: normal;
    padding-top: 2rem;
    font-weight: 400;
    padding-bottom: 1rem;
    line-height: normal;
    letter-spacing: 0.27px;

}
.raise-do {
    color: #000;
    font-family: Poppins;
    padding-top: 1rem;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.247px;
}

.raise-btn-con {
    display: flex;
    gap: 2rem;
    padding-top: 2rem;
    justify-content: end;
}
.raise-btn {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.2rem;
    font-style: normal;
    cursor: pointer;
    border-radius: 5.151px;
    background: var(--s, #622E27);
    border: none;
    font-weight: 400;
    width: 14vw;
    height: 6vh;
    line-height: normal;
    letter-spacing: 0.247px;
}
.raise-do-file {
    display: flex;
    gap: 2rem;
   padding-top: 2rem;
    align-items: center;
}
.in-which{
    color: #000;
font-family: Poppins;
font-size: 1rem;
font-style: normal;
padding-top: 1vh;
padding-left: 5rem;
text-align: start;
font-weight: 400;
line-height: normal;
letter-spacing: 0.18px;
}

@media screen and (max-width: 960px) {
    .Raise-tickets-container {
        display: flex;
        align-items: center;
        height: auto;
        justify-content: center;
    }
    .raise-ticket-select {
        color: rgba(0, 0, 0, 0.50);
        font-family: Poppins;
        font-size: 1rem;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.225px;
        width: 65vw;
        border: 2px solid rgb(153, 149, 149);
        height: 3rem;
    }
    .Raise-ticket-main {
        height: auto;
    padding: 2rem;
    width: 90vw;
    }
    .raise-do-file {
        display: flex;
        gap: 2rem;
        padding-top: 0rem;
        flex-direction: column;
        align-items: flex-start;
    
    }
    .raise-btn-con {
        display: flex;
        gap: 2rem;
    padding-top: 2rem;
    align-items: flex-end;
        justify-content: center;
    }
    .raise-btn {
       
        width: 10rem;
        height: 3rem;
   
    }
    .raise-enter-req {
        border-radius: 4px;
        width: 86vw;
        font-family: Poppins;
        font-size: 1.2rem;
        height: 18vh;
        border: 2px dashed #000;
    }
    .text-raiseup {
        color: #FFF;
        font-family: Poppins;
        font-size: 1.2rem;
        font-style: normal;
        text-align: center;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.24px;
    }
   
  
}




.raiseup-feed-container {
    display: flex;
    align-items: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 112%;
    justify-content: center;
}
.cancel-feed {
    width: 4vw;
    height: 6vh;
}
.check-feed {
    height: 9vh;
    width: 6vw;
}
.raiseup-feed-cont {
    border-radius: 24px;
    height: 58vh;
    padding: 1rem;
    width: 48vw;
    background: var(--s, #622E27);
}
.cancel-feed-c{
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    align-items: center;
}
.raiseupfeed-main{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 48vh;
    flex-direction: column;
}
.text-raiseup {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
}
.raiseup-backhome-btn {
    color: #FFF;
    font-family: Poppins;
    width: 15vw;
    border: none;
    font-size: 1rem;
    border-radius: 5px;
    height: 6vh;
    background: var(--PRI, #C64709);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.Requestagain {
    color: #FFF;
    font-family: Poppins;
    background: var(--s, #622E27);
    font-size: 20px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #FFF;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.raise-popup-requst{
    width: 50vw;
}
.raise-popup-requst {
    width: 35vw;
    display: flex;
    justify-content: center;
}


@media screen and (max-width: 960px) {
    .cancel-feed {
        width: 7vw;
        height: 6vh;
    }
    .text-popup {
        color: #FFF;
        font-family: Poppins;
        font-size: 1.2rem;
        font-style: normal;
        text-align: center;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.24px;
    }
    .popup-feed-cont {
        border-radius: 24px;
        height: auto;
        width: auto;
        background: var(--s, #622E27);
    }
    .popupfeed-main {
    display: flex;
    align-items: center;
    width: 50vw;
    justify-content: space-evenly;
    height: 48vh;
    flex-direction: column;
}
}




.logo-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .logo-image img {
    position: absolute;
    z-index: 2;
  }
  
  .shadow {
    position: relative;
    width: 60px;
    height: 30px;
    background: radial-gradient(
      ellipse at center,
      #c64709 0%,
      rgba(237, 237, 237, 0) 50%
    );
    left: 2vh;
    transform: translateY(40px);
    opacity: 0;
  }
  

  .logo-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .logo-image img {
    position: absolute;
    z-index: 2;
  }
  
  .shadow {
    position: relative;
    width: 60px;
    height: 30px;
    background: radial-gradient(
      ellipse at center,
      #c64709 0%,
      rgba(237, 237, 237, 0) 50%
    );
    left: 2vh;
    transform: translateY(40px);
    opacity: 0;
  }
   */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600&family=Poppins:ital,wght@0,200;0,400;1,300&family=Song+Myung&family=Sora:wght@300;400&display=swap");

/* TicketRise.css */
.ticket-raised-heading {
  padding-inline-start: 1rem;
}
.ticket-raised-heading h2 {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}

.table-001 {
  width: 100%;
  border-collapse: collapse;
  font-size: 15px !important;
  margin-top: 20px !important;
}
td.ticket-raisedby {
  display: flex;
  align-items: center;
  padding-top: 24px;
  gap: 10px;
}
.ticket-status,
.ticket-priority {
  display: none;
}
.ticket-issued-on span {
  border-radius: 16px !important;
  background: var(--Foundation-Blue-B20, #f5f6f7) !important;
  padding: 8px;
  color: var(--Foundation-Blue-B900, #091e42) !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px !important;
}
.responsive-column span {
  padding: 7px 15px;
  border-radius: 20px;
}
.tr-001 {
  border-bottom: 1px solid #ddd !important;
  color: var(--Gray-900, #101828) !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}
.ticket-table-head {
  font-size: 15px !important;
}
.responsive-column span {
  padding: 7px 15px;
  border-radius: 20px;
  font-size: 12px;
}
.pagination-ticket-table {
  margin-bottom: 4rem;
}
.ticket-category span {
  color: var(--Foundation-Blue-B90, #8993a4);
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
td.ticket-category {
  font-size: 12px;
}

th,
td {
  padding: 10px;
  text-align: justify;
}

th {
  border-bottom: 1px solid #eaecf0;
  background: var(--White, #fff);
  color: var(--Foundation-Blue-B500, #42526d);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
td {
  background: var(--White, #fff);
}
td:nth-child(5).active {
  background-color: #4caf50;
  color: white;
}

td:nth-child(5).inactive {
  background-color: #e74c3c;
  color: white;
}

/* side menu */

.Ticket-details {
  padding: 10px;
}
.Ticket-details h3 {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 13px;
  height: 5vh;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
}
.Ticket-details span {
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  padding: 12px 15px 12px 15px;

  background: #efefef;
  color: #000;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.18px;
}
.ticket-user-details {
  display: flex;
  justify-content: space-around;
  padding-inline-start: 12px;
  align-items: center;
  padding-top: 2rem;
}
.user-name p,
.ticket-category-01 p,
.ticket-date p {
  color: #7d7f84;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  margin-bottom: 0px;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
.user-name span,
.ticket-category-01 span,
.ticket-date span {
  color: var(--Foundation-Blue-B500, #42526d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.Query-Description p {
  color: #7d7f84;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
.Query-Description {
  padding-inline-start: 30px;
  padding-top: 30px;
}
.file-upload {
  display: flex;
  gap: 1rem;
}
.file-label {
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background: linear-gradient(0deg, #fff 0%, #fff 100%),
    lightgray 50% / cover no-repeat;
  width: 90px;
  height: 90px;
}
.attached-file-heading {
  color: #7d7f84;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
.attached-file {
  padding-inline-start: 27px;
  padding-top: 28px;
}
.file-upload-label {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.Preview-image img {
  border-radius: 10px;
}
.save-btn-01 {
  border-radius: 8px;
  border: 1px solid #fff;
  background: #c64709;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  padding: 14px 26px 14px 26px;
}

.button-container {
  padding-inline-start: 30px;
  padding-top: 51px;
  display: flex;
  gap: 1rem;
}
.textarea {
  color: black;
  border: 1px solid;
  padding: 10px;
  height: 120px;
  border-radius: 7px;
}
/* selected input styles */
.select-container {
  position: relative;
  /* background: white; */
  border-radius: 10px;
  background: var(--White, #fff);
  width: 200px;
}
.raised-ticket-table {
  padding: 1rem;
}

.select-input {
  cursor: pointer;
  padding: 10px;
  /* border: 1px solid #ccc; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.quert-text-area-div textarea {
  color: var(--Foundation-Grey-G900, #333);

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* .select-input.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.arrow-icon {
  margin-left: 8px;
}

.options-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.options-list li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
} */
/* .select-input {
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
}

.select-input span {
  margin-right: 10px;
}

.arrow-icon {
  position: absolute;
  right: 10px;
}

.option {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
} */
.ticket-raised-main {
  /* background-color: aliceblue; */
  background: #f1f0ee;
}

/* .options div {
  padding: 10px;
  cursor: pointer;
} */
.select-container {
  position: relative;
  width: 200px; /* Adjust the width as needed */
}

.select-input {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.select-input.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.arrow-icon {
  margin-left: auto;
}

.options-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 1;
}

.options-container div {
  padding: 10px;
  cursor: pointer;
}
.ticket-reason {
  font-size: 12px;
}

.options-container div:hover {
  background-color: #f0f0f0;
}

/* Add more styles as needed */

.file-label-01 {
  border-radius: 10px;
  display: flex;
  width: 181px;
  height: 43px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background: var(--White, #fff);
  align-content: stretch;
  justify-content: space-between;
}
textarea.quert-text-area {
  display: flex;
  width: 50vw;
  height: 17vh;
  padding: 12px 38px 12px 15px;
  resize: none;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--White, #fff);
}
.raised-ticket-container {
  height: 25vh;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
}
.input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  flex-direction: column;
}
.button-container-02 {
  display: flex;
  gap: 1rem;
}
.options-list li:hover {
  background-color: #f5f5f5;
}

@media screen and (max-width: 439px) {
  .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    width: 100% !important;
  }
}
/* @media only screen and (max-width: 600px) {
  table {
    width: 80%;
    overflow-x: auto;
  }
} */

/* Media query for smaller screens */
@media only screen and (max-width: 600px) {
  .sideber-main {
    max-width: 100%;
    padding: 0 10px;
  }

  .attached-file {
    width: 100%;
    box-sizing: border-box;
  }

  .attached-file .Preview-image {
    width: 100%;
  }
  /* 
  .button-container {
    flex-direction: column;
  } */

  .button-container button {
    /* width: 100%; */
    margin-bottom: 10px;
  }
  .ticket-raised-main {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 600px) {
  .ticket-user-details > div {
    width: 100%; /* Full width on smaller screens */
  }
  .textarea {
    width: auto;
  }
  .raised-ticket-container {
    height: auto;
    gap: 1rem;
    flex-direction: column;
  }
  .ticket-user-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .ticket-user-details > div {
    width: 48%; /* Adjust as needed, leaving some space for margins */
    box-sizing: border-box;
    margin-bottom: 10px; /* Add margin for spacing between rows */
  }
}
/* Add this media query for smaller screens */
@media only screen and (max-width: 600px) {
  .responsive-column {
    display: none;
  }
  .ticket-status,
  .ticket-priority {
    display: block;
  }
  .ticket-issued-on span {
    font-size: 8px;
  }
}


@media only screen and (max-width: 489px) {
  textarea.quert-text-area {
    width: auto;
    resize: none;
  }
}
