.Footer-container{
   background: var(--sec, #622E27);
    padding: 20px;
    height: calc(85dvh - 80px);
}
.footer-logo-text{
    color: #000;
text-align: center;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 175.5%; 
letter-spacing: 0.5px;
}
.terms-condition{
    color: #FFF;
font-family: Poppins;
font-size: 24px;
font-style: normal;
border-bottom: 1px solid #C64709;
margin-bottom: 1rem;
font-weight: 400;
line-height: 209%; /* 50.16px */
letter-spacing: 0.12px;
}
.terms-condition-text{
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.2px;
}
.terms-condition-main{
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: space-evenly;
}
.termscondition-cnt{
    display: flex;
    gap: 20px;
    width: 100%;
    height: 56vh;
    justify-content: space-between;
    flex-direction: column
}
.twitter{
    width: 64.746px;
    height: 64.746px;
}
.footer-img{
    gap: 20px;
    display: flex;
    margin-left: 2em;
    align-items: center;
}
.footer-text-contai{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
}
.Footer-logo{
    width: 244px;
    height: 69px;
}
.followus{
    color: #FFF;
font-family: Poppins;
font-size: 24.276px;
font-style: normal;
font-weight: 600;
line-height: 149.5%;
letter-spacing: 0.1px;

}
.footer-logo-text{
    color: rgba(255, 255, 255, 0.87);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    width: 326px;
    line-height: 149.5%;
    letter-spacing: 0.08px;
}
.footer-copyright-cont{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:4px;
}
.terms-condition-1{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap:6px;
}
.Footer-logo-terms{
    display: flex;
    height: 56vh;
    justify-content: space-between;
    flex-direction: row;
}
/* Base style for social icons */
.twitter {
    width: 25px;
    height: 25px;
    margin-left:1em;
    transition: transform 0.3s; /* Apply a smooth transition on the transform property */
  }
  
  /* Zoom-in effect on hover */
  .twitter:hover {
    transform: scale(1.5); /* Increase the size of the image to 120% when hovered */
  }


  @media screen and (max-width: 960px) {
    .Footer-logo-terms {
        display: flex;
        height: auto;
        justify-content: space-between;
        flex-direction: column;
    }
    .footer-text-contai {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: auto;
        gap: 20px;
        width: 100%;
        justify-content: center;
        padding-bottom: 5vw;
    }
    .footer-logo-text {
        color: rgba(255, 255, 255, 0.87);
    font-family: Poppins;
    font-style: normal;
    font-size: 2.3vw;
    height: auto;
    width: 69vw;
    font-weight: 400;
    line-height: 149.5%;
    letter-spacing: 0.08px;
    }
    .Footer-container {
        background: var(--sec, #622E27);
        padding: 20px;
        height: auto;
    }
    .termscondition-cnt{
        height: auto;
    }
    .terms-condition {
        color: #FFF;
        font-family: Poppins;
        font-size: 3vw;
        font-style: normal;
        font-weight: 400;
        line-height: 209%;
        letter-spacing: 0.12px;
    }
    .terms-condition-text {
        color: #FFF;
        font-family: Poppins;
        font-size: 2vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.2px;
    }
    .Footer-logo {
        width: 200px;
        height: 55px;
    }
  }
  .a-tag{
    text-decoration: none !important;
  }
  @media screen and (max-width:540px){
    .twitter{
        width: 30px;
        height: 30px;
    }
    .followus{
        font-size: 20.276px;
    }
  }
  @media screen and (max-width: 420px){
    :is(.termscondition-cnt,.Footer-container,.Footer-logo-terms){
        height: auto;
        gap:3rem;
    }
    .terms-condition-main{
        flex-direction: column;
    }
    .terms-condition-text{
        font-size: 3.5vw;
    }
    .terms-condition-1{
        align-items: center;
    }
    .footer-logo-text{
        font-size: 3vw;
    }


  }
