.CrashCurriculum-contain{
    width: 100%;
    height: 100vh;
    background: var(--tir, #F1EBCF);
    display: flex;
    flex-direction: column;
}
.CrashCurriculum-nav{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 3rem;
    height: 10vh;
    background-color: #F1EBCF;
}
.CrashCurriculum-mid-container img {
    width: 8vw;
}

img.doller {
    width: 5vw;
}
.CrashCurriculum-mid-container{
    width: 100%;
    height: 16vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #622E27;
}
.CrashCurriculum-mid-container h1{
color: #FFF;
font-family: Song Myung;
font-size:clamp(1.5rem , 3vw , 3rem);
font-style: normal;
font-weight: 400;
letter-spacing: 0.045rem;
}
.world-1{
   
    width: 5.44113rem;
    height: 5.97444rem;
    position: relative;
    top: -18px;
}
.doller-1{
width: 2.69519rem;
height: 2.87819rem;
position: relative;
top:18px
}
.textbtn-contain-1 {
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: end;
}
.textbtn-contain-1 button {
    width: 148px;
    height: 32px;
    font-size: 1em;
    font-weight: 400;
    border: none;
    border-radius: 4px;
    background-color: #C64709;
    color: #FFF;
}
.CrashCurriculum-body-container{
    width: auto;
    padding-inline: 3rem;
    
    height: 70vh;
    overflow-y: scroll;
}
.CrashCurriculum-body-container::-webkit-scrollbar{
    width: 0;
}
.CrashCurriculum-body{
    height: auto;
    gap:4rem;
    display: flex;
    flex-direction: column;
    padding-block-end:4rem;
}
.CrashCurriculum-body-title h1{
color: var(--pri, #C64709);
font-family: Song Myung;
font-size: 3rem;
height: 10vh;
font-style: normal;
font-weight: 400;
letter-spacing: 0.045rem;
}
.CrashCurriculum-body-video {
    padding: 1rem;
    width: 100%;
    height: 55vh;
    border: 1px solid var(--pri, #C64709);
    display: flex;
    
}
.CrashCurriculum-video-left{
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.5rem;
}
.trinity2{
    height: 40vh;
    object-fit: cover;
    width: 40vw;
}
.CrashCurriculum-video-right{
    width: 100%;
   
}
.CrashCurriculum-pora{
    display: flex;
    flex-direction: row;
    gap:1rem;
    align-items: center;
    justify-content: flex-start;
}
.CrashCurriculum-pora p{
    color: var(--sec, #622E27);
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.CrashCurriculum-pora img {
    width: 2vw;
}
.CrashCurriculum-duration{
    color: var(--sec, #622E27);
font-family: Poppins;
font-size: 1rem;
font-style: normal;
font-weight: 400;
letter-spacing: 0.015rem;
}
.CrashCurriculum-right-title h1{
    color: var(--sec, #622E27);
    font-family: Song Myung;
    font-size: clamp(1.5rem ,2vw + 1rem ,2.5rem);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.0375rem;
}
.CrashCurriculum-right-title p{
color: #000;
font-family: Poppins;
font-size: 1rem;
font-style: normal;
font-weight: 400;
}
.CrashCurriculum-right-button{
    display: flex;
    justify-content: center;
}
.CrashCurriculum-right-button p{
 width: 10.19031rem;
color: #FFF;
font-family: Poppins;
font-size: 1.01588rem;
font-style: normal;
font-weight: 500;
border-radius: 0.47406rem;
background: var(--pri, #C64709);
display: flex;
justify-content: center;
align-items: center;
padding: 0.45em;
}
.CrashCurriculum-right-button span{
    border-radius: 0.1875rem;
    border: 1px solid #FFD13D;
    height: 2.2375rem;
padding: 0.3125rem 1.5625rem;
    color: #000;
font-family: Inter;
font-size: 1.5rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.CrashCurriculum-right-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:0.5rem;
}
.CrashCurriculum-right-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
   
    justify-content: space-between;
}
@media screen and (min-height: 768px) {
    .CrashCurriculum-right-title h1{
        font-size: clamp(1.5rem ,2vw + 1rem ,3rem);
    }
    .CrashCurriculum-right-title p{
        font-size: 1.5rem;
    }
    .CrashCurriculum-body-video {
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .trinity2 {
        height: auto;
        object-fit: cover;
        width: 66vw;
    }
    .CrashCurriculum-right-button span {
        padding: 0.3125rem 0.5625rem;
    }
    .CrashCurriculum-mid-container {
        height: 10vh;
    }
}