.table-container {

  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;  
    }
    .table-content {
        padding: 20px;
        height: 65vh;
    } 
    h2.Previous {
    margin-top: 0;
    }
    .table {
        width: 100%;
        border-collapse: collapse;
        background: #FFFFFF;
        border: 1px solid #cac7c7;
        margin-bottom: 3rem;
    }
    .table-head-view th {
      color: #000;
      font-family: Poppins;
      font-size: 1.2rem;
      width: 11vw;
      padding: 0rem 2rem;
      height: 6vh;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.218px;
    }
    .table-body td {
    padding: 10px;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 19.6463px;
    line-height: 29px;
    letter-spacing: 0.01em;
    color: #000000;
    }
    .raise {  
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    } 
    .view-replay{
    background: rgba(210, 208, 216, 0.59);
    border:none;
    padding: 10px 20px;
    border-radius: 3px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17.4634px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #000000;
    }
    .previous{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        padding-bottom: 1rem;
        font-size: 2rem;
        line-height: 48px;
        letter-spacing: 0.01em;
        color: #000000;
    }
    .popup-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: center;
        backdrop-filter: blur(8px);
        align-items: center;
        z-index: 9999;
      }
      .popup-content { 
        max-width: 400px;
        width: 100%;
        position: relative;
      }
      .close-button {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        background-color: transparent;
        border: none;
        color: #888;
        font-size: 1.2rem;
        cursor: pointer;
      }
      
      .close-button:hover {
        color: #333;
      }