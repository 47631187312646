.text-contactus-head{
    color: var(--pri, #C64709);
    font-family: Fira Sans;
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.text-contactus-content {
    color: #864138;
    font-family: Poppins;
    font-size: 1.2rem;
    font-style: normal;
    padding-top: 2vw;
    padding-bottom: 2vw;
    font-weight: 500;
    letter-spacing: 0.1rem;
    line-height: normal;
    text-transform: uppercase;
}
.PrivacyPolicy-back-btn {
    background-color: #fffced;
    padding: 1rem;
}
.Contactus-container{
    display: flex;
    height: 100vh;
    padding-block: 1em;
    background-color: var(--backgroudcolor);
    align-items: center;
    padding-inline: 5vw;
    flex-direction: column;
}
.text-contact-us-inputs{
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;

}
.input-contactus-textarea{
     border-bottom: 1px solid #536A82;
     padding: 4px 0;
     font-family: Fira Sans;
     font-size: 1.2rem;
     resize: none;
     width: 40vw;
     height: 10vh;
     background-color: var(--backgroudcolor);
}
.input-name-Contactus{
    display: flex;
    flex-direction: column;
}
.label-contactus {
    color: #2E2E2E;
    font-family: Fira Sans;
    font-size: 18.038px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.input-contactus {
    display: flex;
    gap: 1em;
    flex-direction: column;
}
.name-contactus{
    color: var(--pri, #C64709);
    font-family: Fira Sans;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    padding-bottom: 0.5rem;
    line-height: normal;
}
.input-contactus-text{
    border-bottom: 1px solid #536A82;
    border-top: none;
    border-left: none;
    font-family: Fira Sans;
    font-size: 1.2rem;
    border-right: none;
   width: 40vw;
   height: 3rem;
   background-color: var(--backgroudcolor);
}
.send-message{
    color: #FFF;
    font-family: Fira Sans;
    font-size: 18.038px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.059px;
    width: 10rem;
    height: 3rem;
    border-radius: 30.063px;
    border: none;
    background: var(--pri, #C64709);
    box-shadow: 0px 3.006281852722168px 30.062816619873047px 0px #F1BA53;
}
.send-message-con {
    padding-top: 8vh;
    display: flex;
    width: 85vw;
    align-items: center;
    justify-content: start;
   
}
.Address-cont-email{
    color: rgba(0, 0, 0, 0.82);
    padding-top: 2vw;
    font-family: Fira Sans;
    font-size: 18.038px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.Address-cont-text {
    color: #524B4B;
    font-family: Fira Sans;
    font-size: 19.038px;
    padding-top: 2vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.image-email-add{
    display: flex;
    gap: 2vw;
    align-items: center;
   
}
.image-add{
    width: 2vw;
    height: 3vh;
}


@media screen and (max-width: 960px) {
    .text-contact-us-inputs {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
    }
    .input-contactus-text {
        border-bottom: 1px solid #536A82;
        border-top: none;
        border-left: none;
        font-family: Fira Sans;
        font-size: 1.2rem;
        border-right: none;
        width: 77vw;
        height: 3vh;
        background-color: var(--backgroudcolor);
    }
    .send-message-con {
        padding-top: 8vh;
        display: flex;
        width: 85vw;
        align-items: center;
        justify-content: center;
    }
    .Contactus-container {
        display: flex;
        background-color: var(--backgroudcolor);
        align-items: center;
        padding-left: 7vw;
        padding-right: 7vw;
        padding-bottom: 2rem;
        padding-top: 6vh;
        flex-direction: column;
    }
    .input-contactus-textarea {
        border-bottom: 1px solid #536A82;
        border-top: none;
        border-left: none;
        border-right: none;
        font-size: 1.2rem;
        font-family: Fira Sans;
        resize: none;
        width: 77vw;
        height: 10vh;
        background-color: var(--backgroudcolor);
    }
}
