@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

.gradient {
  position: relative;
  width: 100%;
  height: 119vh;
}

.icon-cards-container {
  display: none;
}

.backgraound-gradient {
  width: 100%;
  height: 120lvh;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #FFFCED;
  background-image: url('../../Image//Vector\ 16.png');
}

.premium {
  display: flex;
  width: 100%;
  height: 20vh;
  align-items: center;
  justify-content: center;
}

.premium-title p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 36.0081px;
  line-height: 54px;
  color: rgba(255, 255, 255, 0.87);
}

.premium-vector img {
  width: 40px;
  height: 40px;
}

.premium-cards {
  display: flex;
  width: 100%;
  height: 80vh;
  flex-direction: row;
  gap: 30px;
  align-items: flex-start;
  justify-content: center;
}

.premium-card-1 {
  width: 305px;
  height: 497px;
  padding: 20px 20px 40px 20px;
  border: 1px solid #FFFFFF;
  border-radius: 0.59956rem;
  background: linear-gradient(180deg, rgba(152, 58, 12, 0.08) 0%, rgba(207, 205, 213, 0.56) 100%);
  box-shadow: 0px 3.738983154296875px 3.738983154296875px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(43.46567916870117px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10.2622px;
}

.pre-card-start {
  height: 0px;
}

.pre-card-pro {
  height: 0px;
}

.pre-card-pro p {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-family: Poppins;
  font-size: 22.434px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pre-card-start p {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-family: Poppins;
  font-size: 22.434px;
  font-style: normal;
  font-weight: 500;
  line-height: 141%;
  letter-spacing: 0.224px;

}

.pro-card-price p {
  color: #FFF;
  font-family: Poppins;
  font-size: 44.868px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pre-card-price p {
  color: #000;
  font-family: Poppins;
  font-size: 41.858px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;

}

.get-pro p {
  width: 177.4px;
  height: 35.84px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.869px;
  background: var(--tir, #F1EBCF);
}

.get-pro p:hover {
  background-color: #000;
  color: #FFFFFF;
}

.get-start p {
  width: 177.4px;
  height: 35.84px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins';
  
  background: var(--sec, #622E27);
  color: #FFF;
  font-size: 14.956px;
  font-weight: 500;

}

.get-start p:hover {
  background-color: #FFFFFF;
  color: #000;
}

.pre-card-courses {
  height: 86px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #000;
font-family: Poppins;
font-size: 11px;
font-style: normal;
font-weight: 500;
line-height: normal;

}

.pro-card-courses {
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.premium-card-2 {
  width: 328px;
  height: 542px;
  padding: 20px 20px 40px 20px;
  border-radius: 0.64263rem;
  border: 0.935px solid #FFF;
  background: linear-gradient(180deg, #622E27 0%, #C64709 100%);
  backdrop-filter: blur(43.46567916870117px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 11px;
}

.premium-card-3 {
  box-sizing: border-box;
  padding: 20px 20px 40px 20px;
  width: 306px;
  height: 568px;
  background: linear-gradient(180deg, rgba(251, 251, 252, 0.07) 0%, #E8E7EB 100%);
  border: 1px solid #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(46.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10.2622px;
}

.course-1 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.course-1 p {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12.2602px;
  color: #000000;
line-height: normal;

}

.course-2 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: #FFF;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
 
}
.course-details {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  justify-content: space-between;
  color: #FFF;
font-family: Poppins;
font-size: 13.086px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.course-num{
  color: #000;
font-family: Poppins;
font-size: 13.086px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.course-w{
color: #000000;
}
.course-t{
  color: #000;
font-family: Poppins;
font-size: 13.086px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.inr{
  font-size: 18px !important;
}





@media screen and (max-width: 960px) {
  .premium-cards {
  display: none !important;
}

  .activeCard {
    background: linear-gradient(180deg, #622E27 0%, #C64709 100%);
    color: white;
  }
  .inactive {
    transform: scale(1);
    filter: blur(2.98px);
    background: linear-gradient(180deg, rgba(251, 251, 252, 0.07) 0%, #E8E7EB 100%);
  
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(46.5px);
    backdrop-filter: blur(46.5px);
  }
  .icon-cards__item {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    border-radius: 10px;
    transform-origin: center center;
    transition: transform 0.5s;
  }
  
  .icon-cards__item:nth-child(1) {
    transform: rotateY(0deg) translateZ(35vw);
  }
  
  .icon-cards__item:nth-child(2) {
    transform: rotateY(120deg) translateZ(35vw);
  }
  
  .icon-cards__item:nth-child(3) {
    transform: rotateY(240deg) translateZ(35vw);
  }
  
  :is(.card-1,.card-2,.card-3){
    width: 18rem;
    height: 36rem;
    border: 1px solid #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
  }
.pre-card-start{
    height: 0px;
}
.pre-card-pro{
    height: 0px;
}
.pre-card-pro p{
    display: flex;
    align-items: center;
    justify-content: center; 
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
color: #000000;
}
.pre-card-start p{

    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.01em;
    
    color: #000000;
}
.pro-card-price p {
    

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;



color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pre-card-price p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 44.7805px;
    line-height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
}
.get-pro p{
    width: 177.4px;
    height: 35.84px;
    display: flex;
    align-items: center;
    justify-content: center;   
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height */

height: 35.84px;


background: #ffffff;
border-radius: 2px;

color: #000000;
}
.get-pro p:hover{
    background-color: #000;
    color: #FFFFFF;
}
.get-start p{
  width: 16vw;
  height: 35.84px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 2rem;
  background: #000000;
  border-radius: 2px;
  color: #fff;


background: #000000;
border-radius: 2px;

color: #fff
}
.get-start p:hover{
    background-color: #FFFFFF;
    color: #000;
    
}
.pre-card-courses{
    height: 86px;
    
    display: flex;
    flex-direction: column;
    justify-content: space-around;


}
.pro-card-courses{
  height: auto;
  display: flex;
  width: 16rem;
  flex-direction: column;
  justify-content: space-around;

}
.course-1{
    display: flex;
    flex-direction: row;
    gap:5px;
    align-items: center;
   
}
.course-1 p{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 12.2602px;
line-height: 18px;

color: #000000;
}
.course-2{
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: auto;
  align-items: center;
   
}
.course-2 p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13.3994px;
    line-height: 20px;
  
    
    
    color: #000000
}
.course-details{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-around;
}
.course-number{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #000000;
}
.course-num{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;


color: #000000
}
.course-text{
 font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;



color: #000000
}
.course-wal{
    color: #FFFFFF;
}
.course-test{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;

color: #000000;
}


.icon-cards-container {
 display: flex;
 flex-direction: column;
 align-items: center;
}

.icon-cards {
  position: relative;
  width: 81vw;
  height: 50vw;
  max-width: 380px;
  margin-top: 23vh;
  color: white;
  perspective: 100vw;
}

.icon-cards__content {
 position: absolute;
 width: 91%;
 height: 160%;
 transform-origin: center;
 transform-style: preserve-3d;
 transform: translateZ(-30vw) rotateY(calc(-120deg * var(--card-position)));
 transition: transform 0.5s;
}
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 7px;
  position: absolute;
  bottom: -73px;
}

.button-container button {
 margin: 0 5px;
 padding: 5px 10px;
 border: none;
 background-color: #007bff;
 color: #fff;
 cursor: pointer;
 border-radius: 4px;
}

.button-container button:hover {
 background-color: #0056b3;
}
}

@media screen and (max-width: 820px) {
  :is(.card-1,.card-2,.card-3){
    width: 25rem;
    height: 40rem;
  }
  .button-container{
    bottom: 6rem;
  }

}
@media screen and (max-width:540px) {
  
  .button-container {
    bottom: 5rem;
}
  .user-card {
    width: 75%;
  }
  .user-profile img{
    width: 75px;
    height: 75px;
  }
  .user-details h1{
    font-size: 1rem;
  }
  .user-details h3{
    font-size: 12px;
  }.feedback-content{
    gap:1rem;
  }
  :is(.card-1,.card-2,.card-3){
    width: 18rem;
    height: 32rem;
  }
  .icon-cards{
    width: 62vw;
  }
}

@media screen and (max-width:400px){
  :is(.card-1,.card-2,.card-3) {
    width: 14rem;
    height: 25rem;
}
.inr {
  font-size: 18px !important;
  height: 1rem;
}
.get-pro p{
  width: 83.4px;
}
.pro-card-courses {
  height: auto;
  display: flex;
  width: 12rem;
  flex-direction: column;
  justify-content: space-between;
}
.course-2 p{
  font-size: 12px !important;
}
.course-num{
  font-size: 12px !important;
}
.pre-card-pro p{
  height: 1rem;
}
}